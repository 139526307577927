import React, { Component } from 'react'
import { BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";
import { withRouter } from "react-router";
import history from './../../history';
import logo from '../../images/header/logoTK.png';
import logo2 from '../../images/header/TicketsLK Logo White.png';
import bars from '../../images/header/bars.png';
import { useHistory } from "react-router-dom";
import up2 from '../../images/content/up2.jpg';
import up3 from '../../images/content/up3.jpg';
import up1 from '../../images/content/up1.jpg';
import "../../css/animate.css";
import "../../css/bootstrap.css";
import "../../css/font-awesome.css";
import "../../css/fonts.css";
import "../../css/flaticon.css";
import "../../css/owl.carousel.css";
import "../../css/owl.theme.default.css";
import "../../css/dl-menu.css";
import "../../css/nice-select.css";
import "../../css/magnific-popup.css";
import "../../css/venobox.css";
import "../../css/style4.css";
import "../../css/style.css";
import "../../css/seat.css";
import "../../css/responsive4.css";

export class SideNavBar extends Component {
  componentDidMount(){
    const script = document.createElement("script");
    script.src = "./js/jquery_min.js";
    script.src = "./js/modernizr.js";
    script.src = "./js/bootstrap.js";
    script.src = "./js/owl.carousel.js";
    script.src = "./js/jquery.dlmenu.js";
    script.src = "./js/jquery.sticky.js";
    script.src = "./js/jquery.nice-select.min.js";
    script.src = "./js/jquery.magnific-popup.js";
    script.src = "./js/jquery.bxslider.min.js";
    script.src = "./js/venobox.min.js";
    script.src = "./js/smothscroll_part1.js";
    script.src = "./js/smothscroll_part2.js";
    script.src = "./js/custom4.js";
    script.async = true;
    document.body.appendChild(script);
}

    render() {
        return (
<div className="prs_navigation_main_wrapper">
  <div className="container-fluid">
    <div id="search_open" className="gc_search_box" style={{}}>
      <input type="text" placeholder="Search here" />
      <button><i className="fa fa-search" aria-hidden="true" />
      </button>
    </div>
    <div className="prs_navi_left_main_wrapper">
      <div className="prs_logo_main_wrapper">
      <Link to="/"><img src={logo2} alt="logo" style={{width:130}} /></Link>

      </div>
      <div className="prs_menu_main_wrapper">
        <nav className="navbar navbar-default">
          <div id="dl-menu" className="xv-menuwrapper responsive-menu">
            <button className="dl-trigger">
            <img src={bars} alt="bar_png" /> 
            </button>
            <div className="prs_mobail_searchbar_wrapper" id="search_button"> <i className="fa fa-search" />
            </div>
            <div className="clearfix" />
            <ul className="dl-menu">
              <li  className="parent">  <Link to="/">Home</Link> 
              </li>
              <li className="parent"> <Link to="/movies">Movies</Link> 
                <ul className="lg-submenu"><li className="dl-back"><a href="#">back</a></li>
                  <li onClick={() => this.handleClick('event')}><a >Tenet</a>
                  </li>
                  <li><a href="/movie">Mulan</a>
                  </li>
                  <li><a href="/movie">Paangshu</a></li>
                  <li><a href="/movie">Rookada Panchi</a></li>
                  <li><a href="/movie">Miss Jenis</a></li>
                </ul>
              </li>
              <li  className="parent"> <Link to="/events">Events</Link>  
                <ul className="lg-submenu"><li className="dl-back"><a href="#">back</a></li>
                  <li><a href="/event">Oktober Fest at Bro</a></li>
                  <li><a href="/event">Family Night with C+</a></li>
                  <li><a href="/event">10/10</a></li>
                  <li><a href="/event">DOT.69</a></li>
                  <li><a href="/event">Beat of Youth 2020</a></li>
                  <li><a href="/event">Allegro Haloween Horror Night</a></li>
                  <li><a href="/event">SanSwara සංස්වර</a></li>
                  <li><a href="/event">Dreams සිහිනේ 2020</a></li>
                  <li><a href="/event">මිහිරි ගී මතක තරු සමග</a></li>
                </ul>
              </li>
              <li className="parent"> <Link to="/gallery">Gallery</Link> 
              </li>
              <li className="parent"> <Link to="/blog">Blog</Link>
              </li>
              <li className="parent"> <Link to="/aboutUs">About Us</Link>
              </li>
              {/* <li className="parent"><a href="#">pages</a>
                <ul className="lg-submenu"><li className="dl-back"><a href="#">back</a></li>
                  <li><a href="booking_type.html">Booking-Type</a>
                  </li>
                  <li><a href="confirmation_screen.html">Confirmation-Screen</a>
                  </li>
                  <li><a href="movie_booking">Movie-Booking</a>
                  </li>
                  <li><a href="seat_booking">Seat-Booking</a>
                  </li>
                </ul>
              </li> */}
              <li className="parent"> <Link to="/contact">contact</Link> 
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <div className="prs_navi_right_main_wrapper">
      <div className="prs_slidebar_wrapper">
        <button className="second-nav-toggler" type="button">
        <img src={bars} alt="bar_png" />      
        </button>
      </div>
      <div className="prs_top_login_btn_wrapper">
        <div className="prs_animate_btn1">
          <ul style={{marginBottom: '0rem'}}>
            <li><a href="#" className="button button--tamaya" data-text="Login" data-toggle="modal" data-target="#myModal"><span>Login</span></a>
            </li>
          </ul>
        </div>
      </div>
      <div className="product-heading">
        <div className="con">
          <select style={{display: 'none'}}>
            <option>All Categories</option>
            <option>Movie</option>
            <option>Event</option>
            <option>Video</option>
            <option>Music</option>
            <option>TV-Show</option>
            <option>Radio</option>
          </select>
          <div className="nice-select" tabIndex={0}>
            <span className="current">All Categories</span>
            <ul className="list"><li data-value="All Categories" className="option selected">All Categories</li>
              <li data-value="Movie" className="option">Movie</li>
              <li data-value="Event" className="option">Event</li>
              <li data-value="Video" className="option">Video</li>
              <li data-value="Music" className="option">Music</li>
              <li data-value="TV-Show" className="option">TV-Show</li>
              <li data-value="Radio" className="option">Radio</li>
            </ul>
          </div>
          <input type="text" placeholder="Search..." />
          <button type="submit"><i className="flaticon-tool" />
          </button>
        </div>
      </div>
    </div>
    <div id="mobile-nav" data-prevent-default="true" data-mouse-events="true">
      <div className="mobail_nav_overlay" />
      <div className="mobile-nav-box">
        <div className="mobile-logo">
          <a href="/" className="mobile-main-logo">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="46.996px" height="40px" viewBox="0 0 46.996 40" enableBackground="new 0 0 46.996 40" xmlSpace="preserve">
              <g>
                <g>
                  <path d="M39.919,19.833C39.919,8.88,30.984,0,19.959,0C8.937,0,0,8.88,0,19.833
  c0,10.954,8.937,19.834,19.959,19.834C30.984,39.666,39.919,30.787,39.919,19.833z M35.675,14.425
  c0.379,0,0.686,0.307,0.686,0.683s-0.305,0.683-0.686,0.683c-0.38,0-0.688-0.307-0.688-0.683S35.295,14.425,35.675,14.425z
   M34.482,20.461c0,0.491-0.025,0.976-0.071,1.452l-11.214-4.512l6.396-7.733C32.592,12.311,34.482,16.167,34.482,20.461z
   M19.083,2.277c0.379,0,0.687,0.305,0.687,0.682c0,0.378-0.306,0.684-0.687,0.684c-0.378,0-0.686-0.306-0.686-0.684
  C18.396,2.584,18.704,2.277,19.083,2.277z M19.959,6.031c1.916,0,3.743,0.372,5.416,1.042l-6.335,7.662l-6.252-6.82
  C14.906,6.718,17.351,6.031,19.959,6.031z M3.128,16.473c-0.378,0-0.687-0.306-0.687-0.684c0-0.377,0.307-0.682,0.687-0.682
  c0.38,0,0.686,0.305,0.686,0.682C3.814,16.167,3.508,16.473,3.128,16.473z M5.535,22.119c-0.063-0.545-0.098-1.098-0.098-1.658
  c0-3.612,1.339-6.911,3.547-9.444l6.502,7.095L5.535,22.119z M10.462,35.354c-0.379,0-0.687-0.306-0.687-0.683
  s0.307-0.682,0.687-0.682c0.379,0,0.687,0.305,0.687,0.682S10.842,35.354,10.462,35.354z M6.925,26.828l10.4-4.186l0.239,12.052
  C12.88,33.921,8.956,30.922,6.925,26.828z M19.513,22.326c-1.529,0-2.771-1.232-2.771-2.752c0-1.521,1.241-2.753,2.771-2.753
  c1.53,0,2.771,1.232,2.771,2.753C22.284,21.096,21.043,22.326,19.513,22.326z M29.939,33.99c-0.378,0-0.686-0.308-0.686-0.683
  c0-0.377,0.307-0.683,0.686-0.683s0.688,0.306,0.688,0.683C30.626,33.683,30.319,33.99,29.939,33.99z M22.482,34.672
  l-0.246-12.388l10.846,4.365C31.098,30.799,27.177,33.854,22.482,34.672z M35.314,34.585c-1.837,1.531-6.061,4.306-6.061,4.306
  C37.652,36.448,45.953,40,45.953,40l1.043-8.658C41.41,30.454,38.125,32.244,35.314,34.585z" />
                </g>
              </g>
            </svg><span>TicketsLK</span>
          </a>
          <a href="#" className="manu-close"><i className="fa fa-times" /></a>
        </div>
        <ul className="mobile-list-nav">
          <li><a href="about">OVERVIEW</a>
          </li>
          <li><a href="movies">MOVIES</a>
          </li>
          <li><a href="events">EVENTS</a>
          </li>
          <li><a href="gallery">GALLERY</a>
          </li>
          <li><a href="blog">BLOG</a>
          </li>
          <li><a href="contact">CONTACT</a>
          </li>
        </ul>
        <div className="product-heading prs_slidebar_searchbar_wrapper">
          <div className="con">
            <select style={{display: 'none'}}>
              <option>All Categories</option>
              <option>Movie</option>
              <option>Event</option>
              <option>Video</option>
              <option>Music</option>
              <option>TV-Show</option>
              <option>Radio</option>
            </select><div className="nice-select" tabIndex={0}><span className="current">All Categories</span><ul className="list"><li data-value="All Categories" className="option selected">All Categories</li><li data-value="Movie" className="option">Movie</li><li data-value="Event" className="option">Event</li><li data-value="Video" className="option">Video</li><li data-value="Music" className="option">Music</li><li data-value="TV-Show" className="option">TV-Show</li><li data-value="Radio" className="option">Radio</li></ul></div>
            <input type="text" placeholder="Search..." />
            <button type="submit"><i className="flaticon-tool" />
            </button>
          </div>
        </div>
        <div className="achivement-blog">
          <ul className="flat-list">
            <li>
              <a href="#"> <i className="fa fa-facebook" />
                <h6>Facebook</h6>
                <span className="counter">12546</span>
              </a>
            </li>
            <li>
              <a href="#"> <i className="fa fa-twitter" />
                <h6>Twiter</h6>
                <span className="counter">12546</span>
              </a>
            </li>
            <li>
              <a href="#"> <i className="fa fa-pinterest" />
                <h6>Pinterest</h6>
                <span className="counter">12546</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="prs_top_login_btn_wrapper prs_slidebar_searchbar_btn_wrapper">
          <div className="prs_animate_btn1">
            <ul>
              <li><a href="#" className="button button--tamaya" data-text="Login" data-toggle="modal" data-target="#myModal"><span>Login</span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        )
    }
}

export default SideNavBar;
