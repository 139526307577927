import React, { Component } from 'react'
import "../../css/style.css";
import "../../css/seat.css";
import Screen from '../../images/content/screen.png';
// import "../../css/responsive.css";
import { Link } from 'react-router-dom';
import PrimarySearchAppBar from '../../components/navBar/navBar';
import LibertyLite from './libertyLite';
import SeatPlan from './seatPlans';
import queryString from 'query-string';
import Spinner from 'react-bootstrap/Spinner'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';


var movieData = []
var seatdata = []
const Mid = 0
var MoviedatatoPlan = []
var selectedSeats = []
var seats = [] 

export class seatBooking extends Component {

  constructor(props){
    super(props);
    this.state = {
      seatSdata: [], 
      MoviedatatoPlan: [], MovieSeatList: [],
      dataLoaded: false,
      value: 0,
      selectedSeats: [],
      seatTypeId: [],
    }
      
    this.onSeatData = this.onSeatData.bind(this);
    this.handleClose = this.handleClose.bind(this);
		this.handleClickOpen =this.handleClickOpen.bind(this);
    this.handleChanged = this.handleChanged.bind(this);
    this.clearSeats = this.clearSeats.bind(this);
		// this.submitData = this.submitData.bind(this);
  }

  onSeatData(seatSdata) {
    this.setState({seatSdata});
    seatdata = seatSdata
    console.log(seatdata)
  }

  
  getSeats = async() => {
    try{
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
      };
      await fetch(
        // `http://595977570d12.ngrok.io/v1/seat?venueId=`+ movieData.venueId + `&movieId=`+ movieData.Mid + 
        //     `&showtimeId=` + movieData.showtimeId + `&movieDate=2021-08-05`
        `${process.env.REACT_APP_DEV_BASE_URL}/v1/seat?venueId=`+ movieData.theaterId +`&movieId=`+ movieData.movieid +
        `&showtimeId=`+ movieData.showtimeId + `&movieDate=`+ movieData.movieDate, requestOptions)
          .then(response => response.json())
          .then((MovieSeatList) => {
            console.log(MovieSeatList.data.reservedSeats.reservedSeat)
            this.setState({ 
              MovieSeatList: MovieSeatList.data.reservedSeats.reservedSeat,
              dataLoaded: true,
             })
          }).catch(console.log);
    }
    catch (error) {
      console.log("error", error)
    }
  }
  

    componentDidMount() {
      let movieInfo = queryString.parse(this.props.location.search)
      console.log(movieInfo.movieid) 
      this.setState(movieInfo)
      console.log(movieInfo.name)          
      movieData = movieInfo 
      this.getSeats()
      MoviedatatoPlan = movieData

      // const requestOptions = {
      //   method: 'GET',
      //   headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
      // };
      // fetch(
        // `${process.env.REACT_APP_DEV_BASE_URL}/v1/seat?venueId=`+ movieData.venueId + `&movieId=`+ movieData.Mid + 
        //     `&showtimeId=` + movieData.showtimeId + `&movieDate=2021-08-05`
        // `${process.env.REACT_APP_DEV_BASE_URL}/v1/seat?venueId=90&movieId=3232&showtimeId=2130&movieDate=2021-08-05`
        //     , requestOptions)
        //   .then(response => response.json())
        //   .then((MovieSeatList) => {
        //     console.log(MovieSeatList.data.reservedSeats.reservedSeat)
        //     this.setState({ 
        //       MovieSeatList: MovieSeatList.data.reservedSeats.reservedSeat,
        //       dataLoaded: true,
        //      })
        //   }).catch(console.log);

    }

    handleChanged(e,seatType){
      console.log(seatType)
      // if(this.state.seatTypeId.indexOf(seatType) !== -1){
      //   alert("Yes, the value exists!")  
      // } else {
      //   this.state.seatTypeId.push(seatType);
      //   this.setState({seatTypeId:this.state.seatTypeId})
      // }
      this.setState({
        seatTypeId: seatType
      })
      console.log(this.state.seatTypeId)
      let isChecked = e.target.checked;
      seats.push(e.target.name)
      if(isChecked){
          alert(seats);
      }else{
          seats = seats.filter((name) => e.target.name !== name);
          alert("removed",e.target.name)
      }
      console.log(seats)
    }

    clearSeats(){
      seats = []
    }

    handleClickOpen()  {
      // setOpen(true);
      // console.log('Click')
      this.setState({dialogOpen: true});
    };
  
    handleClose()  {
      // setOpen(false);
      this.setState({dialogOpen:false});
    };
    
    onPlusClick = () => {
      if (this.state.value < seats.length)
      {
        this.setState({ ...this.state, value: this.state.value + 1 });
      }
      else{
        this.setState({...this.state, value: seats.length})
      }
    };

    onMinusClick = () => {
      if (this.state.value > 0)  {
      this.setState({ ...this.state, value: this.state.value - 1 });
      }
      else{
        this.setState({...this.state, value: 0})
      }
    };

    render() {
        const {MovieSeatList, dataLoaded, selectedSeats} = this.state;
        const { dialogOpen } = this.state;   
        const SeatCounts = seats.length
        console.log(SeatCounts)
        const AdSeatCount = seats.length - this.state.value
        const childSeatCount = this.state.value
        let seatType = MovieSeatList.map(a => a.seatType)
        let seatTypeId = MovieSeatList.map(b => b.seatTypeId)
        console.log(MovieSeatList);
        // MoviedatatoPlan = MovieSeatList
        console.log(MovieSeatList)
        console.log(movieData)
        console.log(seatType)
        console.log(seatTypeId)
        console.log(selectedSeats)

        
if (!dataLoaded) {
  return (
  <div>
    <Spinner animation="grow" variant="danger" />
    <Spinner animation="grow" variant="danger" />
    <Spinner animation="grow" variant="danger" />
  </div>
  );
}
else{

        return (
<div>
<PrimarySearchAppBar />
<div className="st_bt_top_header_wrapper float_left" style={{paddingTop:90}}>
  <div className="container container_seat">
    <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div className="st_bt_top_back_btn st_bt_top_back_btn_seatl float_left"> <Link to={'movieBooking/?movieid=' + movieData.movieid} style={{ textDecoration: 'none' }}> &nbsp;Back</Link>
        </div>
        {/* <div className="cc_ps_quantily_info cc_ps_quantily_info_tecket">
          <p>Select Tickets</p>
          <div className="select_number">
            <button onclick="if (!window.__cfRLUnblockHandlers) return false; changeQty(1); return false;" className="increase"><i className="fa fa-plus" />
            </button>
            <input type="text" name="quantity" defaultValue={1} size={2} id="input-quantity" className="form-control"/>       
            <button onclick="if (!window.__cfRLUnblockHandlers) return false; changeQty(0); return false;" className="decrease"><i className="fa fa-minus" />
            </button>
            <p>Select Half Tickets</p>
          </div>
          <input type="hidden" name="product_id" />
        </div> */}
      </div>
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div className="st_bt_top_center_heading st_bt_top_center_heading_seat_book_page float_left">
          <h3>{movieData.name} - English</h3>
          <h4>{movieData.theater}</h4>
          <h4>{movieData.movieDate} , {movieData.showTime} </h4>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div className="st_bt_top_close_btn st_bt_top_close_btn2 float_left"> <a href="#/movies" style={{color:"#fff"}}><i className="fa fa-times" /></a>
        </div>
        <div className="st_seatlay_btn float_left"> 
        {/* <Link to={'/bookingSummary?movieid=' + movieData.movieid + '&movieName=' + movieData.name + '&theater=' + movieData.theater + '&venueId=' + 
          movieData.theaterId + '&showtimeId=' + movieData.showtimeId + "&showTime=" + movieData.showTime +
          '&movieDate=' + movieData.movieDate + '&seats=' + seatdata +'&seatType=' + seatType + '&seatTypeId=' + seatTypeId}
        style={{ textDecoration: 'none' }}>Proceed</Link> */}
        <Link onClick={this.handleClickOpen} style={{ textDecoration: 'none' }}>Proceed</Link>
                <Dialog open={dialogOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Select Category</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                      To subscribe to this website, please enter your email address here. We will send updates
                      occasionally.
                    </DialogContentText>
                <ul>
                  <li> 
                    Adult Tickets &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;
                    <TextField
                      value={AdSeatCount}
                      id="outlined-adornment-small-Adult"
                      // defaultValue="50"
                      variant="outlined"
                      size="small"
                      style={{ width: 48, height: 35}}
                      labelWidth={0}
                      disabled="true"
                    />
                    <br/>
                  </li>
                  <li>Child Tickets
                    <div style={{ display: "flex", alignItems: "center" }}>									
                    <IconButton
                      onClick={this.onMinusClick}
                      aria-label="minus"
                      style={{ marginTop: 15 }}
                    >
                    <RemoveCircleIcon fontSize="inherit" />
                    </IconButton>
                    <TextField
                      value={this.state.value}
                      id="outlined-adornment-small-Child"
                      // defaultValue="50"
                      variant="outlined"
                      size="small"
                      style={{ width: 48, height: 35 ,marginTop: 15}}
                      labelWidth={0}
                      disabled="true"
                    />
                    <IconButton
                      onClick={this.onPlusClick}
                      aria-label="plus"
                      style={{ marginTop: 15 }}
                    >
                      <AddCircleIcon fontSize="inherit" />
                    </IconButton>
                    </div>
                  </li>
                </ul>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleClose} color="secondary" variant="contained">
                      Cancel
                    </Button>
                    <Button color="primary" variant="contained">
                      <Link to={'/bookingSummary?movieid=' + movieData.movieid + '&movieName=' + movieData.name + '&theater=' + movieData.theater + '&venueId=' + 
                          movieData.theaterId + '&showtimeId=' + movieData.showtimeId + "&showTime=" + movieData.showTime +
                          '&movieDate=' + movieData.movieDate + '&seats=' + seats +'&seatType=ODC'
                          + '&seatTypeId='  + this.state.seatTypeId
                        + '&adultSeats=' + AdSeatCount +'&childSeats=' + childSeatCount }
                          style={{ textDecoration: 'none', color: "#fff" }} onClick={this.clearSeats}>
                        Submit
                      </Link>
                    </Button>
                    </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="st_seatlayout_main_wrapper float_left">
          <div className="container container_seat">
            <div className="st_seat_lay_heading float_left">
            {/* <h3>Liberty Lite</h3> */}
            </div>
            <div className="st_seat_full_container">
            <div className="st_seat_lay_economy_wrapper float_left">
                <div className="screen">
                <img src={Screen} alt="Screen"/>
                </div>
                {this.state.MovieSeatList.map((row) => (
                <>
                  <div className="st_seat_lay_economy_heading float_left">
                    <h3>{row.seatType}</h3>
                  </div>
                  <div className="st_seat_lay_row float_left">
                      <ul>
                        {row.seatRows.map((seatRow,index) =>(
                          <div className="st_seat_lay_row float_left">
                          <li className="st_seat_heading_row" key={index}>
                            {seatRow.row}
                          </li>
                          {seatRow.seats.map((seat, idx) => (
                                <ul className={seat.section}>
                                <li className={seat.booked} key={idx}>
                                  <input
                                    type="checkbox"
                                    id={seat.seatId}
                                    name={seat.number}
                                    onChange={(e) => this.handleChanged(e,row.seatTypeId)}
                                    disabled={seat.selected}
                                  />
                                  <label htmlFor={seat.seatId} />
                                </li>
                                </ul>
                          ))}
                        </div>
                        ))}
                      </ul>
                  </div>
                </>
                ))}
              </div>
            </div>
          </div>
        </div>
</div>

    ) 
  }}
}

export default seatBooking
