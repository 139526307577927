import React, { useEffect, useState } from "react";
import PrimarySearchAppBar from "../../components/navBar/navBar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import MovieFilterIcon from "@material-ui/icons/MovieFilter";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import GavelIcon from "@material-ui/icons/Gavel";

import TnC from "./termOfUse";
import FAQ from "./FAQ";
import CardData from "./cardData";
import PersonalData from "./userData";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserPersonalInfo() {
  const [value, setValue] = useState(0);
  const [accountVerify, setAccountVerify] = useState(false);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [mobileDisable, setMobileDisable] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otpDisabled, setOtpDisabled] = useState(false);
  const [userId, setUserId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("AccessToken");
    if (!token || token === "") {
      setAccountVerify(true);
      setMobileDisable(false);
      setOtpDisabled(false);
    }
  }, []);

  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  const verifyHandleClose = () => {
    setAccountVerify(false);
    setShowOtp(false);
  };

  const userNameSet = (e) => {
    setUserName(e.target.value);
  };

  const mobileSet = (e) => {
    setMobile(e.target.value);
  };

  const emailSet = (e) => {
    setEmail(e.target.value);
  };

  const otpSet = (e) => {
    setOtp(e.target.value);
  };

  const verifyMobile = () => {
    setMobileDisable(true);
    getOtp();
  };

  const getOtp = async () => {
    try {
      await fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/user/register`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobileNumber: mobile,
          email: email,
        }),
      })
        .then((response) => response.json())
        .then((mobileVerify) => {
          if (mobileVerify.success) {
            setShowOtp(true);
          } else {
            setShowOtp(false);
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const confirmOtp = async () => {
    setOtpDisabled(true);
    try {
      await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/v1/user/otp/validate`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobileNumber: mobile,
            otp: otp,
          }),
        }
      )
        .then((response) => response.json())
        .then((verifyData) => {
          if (verifyData.success) {
            localStorage.setItem("AccessToken", verifyData.data.token);
            localStorage.setItem("userId", verifyData.data.id);
            localStorage.setItem('mobileNo',mobile);
            localStorage.setItem('email',email);
            localStorage.setItem('userName',userName);
            verifyHandleClose();
          } else {
            alert("wrong OTP");
            setOtpDisabled(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <PrimarySearchAppBar />
      {/* <h4 style={{ paddingTop: 70 }}>profile</h4> */}
      <Box sx={{ flexGrow: 1, m: 1, pt: 8, height: "500px" }}>
        <Grid container spacing={2} sx={{ background: "#e6e6e6" }}>
          <Grid item xs={12} md={3}>
            <Paper elevation={2}>
              <PersonalData />
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <Paper elevation={2}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={tabHandleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Cards"
                      {...a11yProps(0)}
                      icon={<AccountBalanceWalletIcon />}
                    />
                    <Tab
                      label="Bookings"
                      {...a11yProps(1)}
                      icon={<MovieFilterIcon />}
                    />
                    <Tab
                      label="FAQ"
                      {...a11yProps(2)}
                      icon={<LiveHelpIcon />}
                    />
                    <Tab label="T&C" {...a11yProps(3)} icon={<GavelIcon />} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <CardData />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  Item Two
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <FAQ />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <TnC />
                </TabPanel>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Verify Dialog */}
      <Dialog open={accountVerify} onClose={verifyHandleClose}>
        <DialogTitle>Please Verify your Mobile Number to Continue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To Login to this website, please enter your Mobile Number & Email to
            Verify.(An Email & SMS will be sent with an OTP)
          </DialogContentText>
          <Stack direction="column" spacing={2} sx={{ width: "50%" }}>
            {!showOtp ? (
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  id="username"
                  label="Name"
                  type="text"
                  variant="standard"
                  value={userName}
                  onChange={userNameSet}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="mobileNo"
                  label="Mobile Number"
                  type="mobile"
                  variant="standard"
                  value={mobile}
                  onChange={mobileSet}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="emailAddress"
                  label="Email Address"
                  type="email"
                  variant="standard"
                  value={email}
                  onChange={emailSet}
                />
              </>
            ) : null}
            {showOtp ? (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="OTP"
                type="number"
                variant="standard"
                value={otp}
                onChange={otpSet}
              />
            ) : null}
          </Stack>
          <br />
          <Stack direction="row" spacing={2}>
            {!showOtp ? (
              <Button
                onClick={verifyMobile}
                disabled={mobileDisable}
                variant="outlined"
              >
                Verify
              </Button>
            ) : (
              <>
                <Button
                  onClick={confirmOtp}
                  disabled={otpDisabled}
                  variant="contained"
                >
                  Submit
                </Button>
                <Button onClick={getOtp} variant="outlined" color="info">
                  Resend OTP
                </Button>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={verifyHandleClose} color="error" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
