import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import visa from "../../images/header/visa.png";
import master from "../../images/header/master.png";
import Spinner from "react-bootstrap/Spinner";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import makeStyles from "@material-ui/styles/makeStyles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  customColor: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "white" },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CardData() {
  var path = queryString.parse(useLocation().search);
  // console.log(path);

  const classes = useStyles();
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [removedCard, setRemoveCard] = useState([]);
  const [addCardResponse, setAddCardResponse] = useState([]);
  const [cardId, setCardId] = useState("");

  const handleClickOpen = (cardid) => {
    setCardId(cardid);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const errorHandleClose = () => {
    setErrorOpen(false);
    fetchData();
  };

  const verifyHandle = async (cardId) => {
    // console.log(cardId);
    const userId = localStorage.getItem("userId");
    window.location.href = `https://tbs.tickets.lk/card/verify.php?userId=${userId}&cardId=${cardId}`;
  };

  useEffect(() => {
    const token = localStorage.getItem("AccessToken");
    if (!token || token === "") {
      // navigate("/");
    } else {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    const userId = localStorage.getItem("userId");
    const url = `https://tbs.tickets.lk/card/get.php?userId=${userId}`;
    const requestOptions = (token) => {
      return {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
    };
    try {
      const response = await fetch(url, requestOptions());
      const json = await response.json();
      // console.log(json);
      setCardData(json.data);
      setDataLoaded(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeCard = async () => {
    // console.log(cardId);
    removeCards();
  };
  const cardata = cardData.cardDetails;

  const removeCards = async () => {
    // console.log(cardata);
    const userId = localStorage.getItem("userId");
    const url = `https://tbs.tickets.lk/card/delete.php?userId=${userId}&cardId=${cardId}`;
    const requestOptions = (token) => {
      return {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
    };
    try {
      const response = await fetch(url, requestOptions());
      const json = await response.json();
      // console.log(json);
      setRemoveCard(json.data);
      setOpen(false);
      fetchData();
    } catch (error) {
      console.log("error", error);
      setOpen(false);
      fetchData();
    }
    // if(removedCard.success){
    //   fetchData();
    // } else {
    //   console.log('error')
    // }
  };

  const addCard = async () => {
    const userId = localStorage.getItem("userId");
    window.location.href = `http://tbs.tickets.lk/pay/genie.php?userId=${userId}&reference=0&amount=0&cardSave=1`;
  };

  if (!dataLoaded) {
    return (
      <>
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
        {/* <Backdrop
              sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop> */}
      </>
    );
  } else {
    let cards = cardData.cardDetails;
    // console.log(cards);
    return (
      <>
        {cards.length > 0 ? (
          <div className="scroll_to_booked_tickets">
            <h4 className="card-heading">Your Saved Cards</h4>
            {cards.map((cardData) => (
              <div className="col">
                <div className="CardPanel">
                  <div className="Credit-card" style={{ borderRadius: "15px" }}>
                    <div className="number">{cardData.maskCardNo}</div>
                    {/* <div className="expiry-date">10/17</div> */}
                    {/* <div className="owner">Jane Doe</div> */}
                    {cardData.cardType === "MASTERCARD" ? (
                      <img className="card-logo" src={master} alt="cardLogo" />
                    ) : (
                      <img className="card-logo" src={visa} alt="cardLogo" />
                    )}
                    <div className="remove-button">
                      {!cardData.active ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => verifyHandle(cardData.cardId)}
                        >
                          Verify
                        </Button>
                      ) : null}
                      <IconButton
                        color="secondary"
                        onClick={() => handleClickOpen(cardData.cardId)}
                        size="large"
                        className={classes.customColor}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                      {/* <Button variant="contained" color="secondary">Remove</Button> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <h4>No Cards Available</h4>
          </div>
        )}
        <div style={{ float: "right" }}>
          <Fab
            size="medium"
            color="secondary"
            aria-label="add"
            onClick={addCard}
          >
            <AddIcon />
          </Fab>
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to remove the saved Card?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your saved card will be removed from the TicketsLK services
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Cancel
            </Button>
            <Button
              onClick={removeCard}
              autoFocus
              color="secondary"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* error dialog modal */}

        <Dialog
          open={errorOpen}
          onClose={errorHandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">
                    {`Error Code : ${path.code}`}
                  </DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {path.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={errorHandleClose}
              autoFocus
              variant="contained"
              color="primary"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
