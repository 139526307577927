import React,{useState, useEffect} from "react";
import floor from '../../../images/event_seats_plan/freddy/floor.png'
import whiskers01 from '../../../images/event_seats_plan/freddy/whiskers_01.png';
import vipBar from '../../../images/event_seats_plan/freddy/vip_bar_01.png';
import coolCatFurball from '../../../images/event_seats_plan/freddy/whiskers_01.png';
import topCat01 from '../../../images/event_seats_plan/freddy/top_cats_01_02.png'
import Spinner from 'react-bootstrap/Spinner'


export default function Freddy({seatNohandleChecked, seatArrangement}){

    const [dataLoad, setDataLoaded] = useState(false);
    const [seatList, setSeatList] =useState([])
    
    // setSeatList(seatArrangement.reservedSeat)
    console.log(seatArrangement)

    useEffect(() => {
        setSeatList(seatArrangement)
        console.log(seatList)
    }, [])
    

    

    // if(!dataLoad){
    //     return(
    //         <Spinner animation="grow" variant="danger" />
    //     )
    // } else {
        // console.log(seatList[6].seatRows[0].seats[0].selected)
        return(
            <div className="st_seat_full_container float_left ">
                <div className="row seatPlan-screen" style={{background:'#2b2d3d', }}>
                    <img src={floor} alt='floorPlan'/>
                    <div className="checkbox-container">
                    <input id='TC1' name='3563' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                     className='topCat01' 
                     disabled={seatArrangement.reservedSeat[6].seatRows[0].seats[0].selected}/>
                     <label htmlFor='TC1' className='btn'>TopCat</label> 
                     <h5 className="topCat01-lbl">Top Cat 01</h5>           
                    </div>
                    <div className="checkbox-container">
                    <input id='TC2' name='3564' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                     className='topCat02' 
                     disabled={seatArrangement.reservedSeat[7].seatRows[0].seats[0].selected}/>
                     <label htmlFor='TC2' className='btn'>TopCat2</label>     
                     <h5 className="topCat02-lbl">Top Cat 02</h5>        
                    </div>
                    <div className="checkbox-container-01">
                    <input id='FC1' name='3566' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                     className='furBall-cleo' 
                     disabled={seatArrangement.reservedSeat[9].seatRows[0].seats[0].selected}
                     />
                    <label htmlFor='FC1' className='btn'>cleo</label>
                    <input id='FM1' name='3567' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                     className='furBall-cleo' 
                     disabled={seatArrangement.reservedSeat[10].seatRows[0].seats[0].selected}
                     />
                     <label htmlFor='FM1' className='btn' style={{marignRight:'15px'}}>magenta</label>
                    <input id='FF1' name='3568' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                     className='furBall-cleo-1' 
                     disabled={seatArrangement.reservedSeat[0].seatRows[0].seats[0].selected}
                     />
                     <label htmlFor='FF1' className='btn'>FREDAL</label>  
                    <input id='FB1' name='3569' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                     className='furBall-cleo-1' 
                     disabled={seatArrangement.reservedSeat[1].seatRows[0].seats[0].selected}
                     />
                     <label htmlFor='FB1' className='btn'>BITTY</label>
                        
                    <h6 className="furBall-lbl">Cleo</h6> 
                    <h6 className="furBall-lbl-1">Magenta</h6>      
                    <h6 className="furBall-lbl-2">Fredal</h6>    
                    <h6 className="furBall-lbl-3">Bitty</h6>    
                    </div>
                    <div className="checkbox-container-02">
                        <div>
                            <input id='CB4' name='3572' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                            className='vip-bar' 
                            disabled={seatArrangement.reservedSeat[4].seatRows[0].seats[0].selected}
                            />
                            <label htmlFor='CB4' className='btn'>CB4</label>  
                            <h5 className="vip-bar-lbl">CB4</h5>
                        </div>
                        <div>
                            <input id='CB3' name='3572' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                            className='vip-bar' 
                            disabled={seatArrangement.reservedSeat[4].seatRows[0].seats[1].selected}
                            />
                            <label htmlFor='CB3' className='btn'>vipBar3</label>
                            <h5 className="vip-bar-lbl">CB3</h5>  
                        </div>
                        <div>
                            <input id='CB2' name='3572' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                            className='vip-bar' 
                            disabled={seatArrangement.reservedSeat[4].seatRows[0].seats[2].selected}
                            />
                            <label htmlFor='CB2' className='btn'>vipBar2</label> 
                            <h5 className="vip-bar-lbl">CB2</h5> 
                        </div>
                        <div>
                            <input id='CB1' name='3572' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                            className='vip-bar' 
                            disabled={seatArrangement.reservedSeat[4].seatRows[0].seats[3].selected}
                            />
                            <label htmlFor='CB1' className='btn'>vipBar1</label>  
                            <h5 className="vip-bar-lbl">CB1</h5>
                        </div>
                    </div>
                    <div className="checkbox-container-02">
                        <div>
                            <input id='CC6' name='3570' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                            className='cool-Cats' 
                            disabled={seatArrangement.reservedSeat[2].seatRows[0].seats[0].selected}/>
                            <label htmlFor='CC6' className='btn'>coolCats6</label>  
                            <h5 className="cool-cat-lbl">CC6</h5>
                        </div>
                        <div>
                            <input id='CC5' name='3570' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                            className='cool-Cats' 
                            disabled={seatArrangement.reservedSeat[2].seatRows[0].seats[1].selected}/>
                            <label htmlFor='CC5' className='btn'>coolCats5</label>  
                            <h5 className="cool-cat-lbl">CC5</h5>
                        </div>
                    </div>
                    <div className="checkbox-container-03">
                    <div >
                        <input id='CC4' name='3570' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='cool-Cats-2' 
                        disabled={seatArrangement.reservedSeat[2].seatRows[0].seats[2].selected}/>
                        <label htmlFor='CC4' className='btn'>coolCats4</label>  
                        <h5 className="cool-2-cat-lbl">CC4</h5>
                    </div>
                    <div >
                        <input id='CC3' name='3570' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='cool-Cats-2' 
                        disabled={seatArrangement.reservedSeat[2].seatRows[0].seats[3].selected}/>
                        <label htmlFor='CC3' className='btn'>coolCats3</label>  
                        <h5 className="cool-2-cat-lbl">CC3</h5>
                    </div>
                    <div>
                        <input id='CC2' name='3570' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='cool-Cats-2' 
                        disabled={seatArrangement.reservedSeat[2].seatRows[0].seats[4].selected}/>
                        <label htmlFor='CC2' className='btn'>coolCats2</label>  
                        <h5 className="cool-2-cat-lbl">CC2</h5>
                    </div>
                    <div> 
                        <input id='CC1' name='3570' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='cool-Cats-2' 
                        disabled={seatArrangement.reservedSeat[2].seatRows[0].seats[5].selected}/>
                        <label htmlFor='CC1' className='btn'>coolCats1</label>  
                        <h5 className="cool-2-cat-lbl">CC1</h5>
                    </div>
                    </div>
                    <div className="checkbox-container-03">
                    <div> 
                        <input id='WC4' name='3571' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='wiskers-corner' 
                        disabled={seatArrangement.reservedSeat[3].seatRows[0].seats[0].selected}
                        />
                        <label htmlFor='WC4' className='btn'>wc4</label> 
                        <h5 className="wiskers-corner-1-lbl">WC4</h5> 
                    </div>
                    <div> 
                        <input id='WC3' name='3571' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='wiskers-corner'
                        disabled={seatArrangement.reservedSeat[3].seatRows[0].seats[1].selected}
                        />
                        <label htmlFor='WC3' className='btn'>wc3</label> 
                        <h5 className="wiskers-corner-1-lbl">WC3</h5>  
                    </div>
                    <br></br>  
                    <div className="checkbox-container-04">
                    <div> 
                        <input id='WC2' name='3571' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='wiskers-corner-1' 
                        disabled={seatArrangement.reservedSeat[3].seatRows[0].seats[2].selected}
                        />
                        <label htmlFor='WC2' className='btn'>wc2</label>
                        <h5 className="wiskers-corner-2-lbl">WC2</h5>   
                    </div>  
                    <div> 
                        <input id='WC1' name='3571' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='wiskers-corner-1' 
                        disabled={seatArrangement.reservedSeat[3].seatRows[0].seats[3].selected}
                        />
                        <label htmlFor='WC1' className='btn'>wc1</label> 
                        <h5 className="wiskers-corner-2-lbl">WC1</h5>  
                    </div>      
                    </div>
                    </div>
                    <div className="checkbox-container-04">
                    <div style={{marginLeft:'25px'}}> 
                        <input id='CP14' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-1' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[0].selected}/>
                        <label htmlFor='CP14' className='btn'>CP14</label>  
                        <h5 className="pit-lbl">CP14</h5>
                    </div>
                    <div> 
                        <input id='CP13' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-1' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[1].selected}/>
                        <label htmlFor='CP13' className='btn'>CP13</label>  
                        <h5 className="pit-lbl">CP13</h5>
                    </div>
                    <div style={{marginLeft:'10px'}}> 
                        <input id='CP12' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-1'
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[2].selected} />
                        <label htmlFor='CP12' className='btn'>CP12</label>
                        <h5 className="pit-lbl">CP12</h5>  
                    </div>
                    <div> 
                        <input id='C11' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-1' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[3].selected}/>
                        <label htmlFor='CP11' className='btn'>CP11</label>
                        <h5 className="pit-lbl">CP11</h5>
                    </div>
                    </div>
                    <div className="checkbox-container-05">
                    <input id='CP10' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-2' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[4].selected}/>
                    <label htmlFor='CP10' className='btn'>CP10</label>
                    <h5 className="pit-2-lbl">CP10</h5>
                    <input id='CP9' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-2' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[5].selected}/>
                    <label htmlFor='CP9' className='btn'>CP9</label>
                    <h5 className="pit-2-lbl">CP9</h5>
                    <input id='CP8' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-2' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[6].selected}/>
                    <label htmlFor='CP8' className='btn'>CP8</label>
                    <h5 className="pit-2-lbl">CP8</h5>
                    <input id='CP7' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-2' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[7].selected}/>
                    <label htmlFor='CP7' className='btn'>CP7</label>
                    <h5 className="pit-2-lbl">CP7</h5>
                    <input id='CP6' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-2' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[8].selected}/>
                    <label htmlFor='CP6' className='btn'>CP6</label>
                    <h5 className="pit-2-lbl">CP6</h5>
                    </div>
                    <div className="checkbox-container-06">
                    <input id='CP5' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-3' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[9].selected}/>
                    <label htmlFor='CP5' className='btn'>CP5</label>
                    <h5 className="pit-3-lbl">CP5</h5>
                    <input id='CP4' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-3' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[10].selected}/>
                    <label htmlFor='CP4' className='btn'>CP4</label>
                    <h5 className="pit-3-lbl">CP4</h5>
                    <input id='CP3' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-3' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[11].selected}/>
                    <label htmlFor='CP3' className='btn'>CP3</label>
                    <h5 className="pit-3-lbl">CP3</h5>
                    <input id='CP2' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-3' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[12].selected}/>
                    <label htmlFor='CP2' className='btn'>CP2</label>
                    <h5 className="pit-3-lbl">CP2</h5>
                    <input id='CP1' name='3565' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='pit-3' 
                        disabled={seatArrangement.reservedSeat[8].seatRows[0].seats[13].selected}/>
                    <label htmlFor='CP1' className='btn'>CP1</label>
                    <h5 className="pit-3-lbl">CP1</h5>
                    </div>
                    <div className="checkbox-container-07">
                    <input id='AC1' name='3573' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='ally' 
                        disabled={seatArrangement.reservedSeat[5].seatRows[0].seats[0].selected}/>
                    <label htmlFor='AC1' className='btn'>AC1</label>
                    <h5 className="ally-lbl">AC1</h5>
                    <input id='AC2' name='3573' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='ally'
                        disabled={seatArrangement.reservedSeat[5].seatRows[0].seats[1].selected}/>
                    <label htmlFor='AC2' className='btn'>AC2</label>
                    <h5 className="ally-lbl">AC2</h5>
                    </div>
                    <div className="checkbox-container-08">
                    <input id='AC3' name='3573' type='checkbox'  onChange={(e) =>seatNohandleChecked(e)} 
                        className='ally-1'
                        disabled={seatArrangement.reservedSeat[5].seatRows[0].seats[2].selected}/>
                    <label htmlFor='AC3' className='btn'>AC3</label>
                    <h5 className="ally-1-lbl">AC3</h5>
                    </div>
                </div>
                </div>
        )
    }

    
// }