import React, { Component, useState } from "react";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import PrimarySearchAppBar from "../../components/navBar/navBar";
import Preloader from "../../components/preloader/preloader";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";
import ShowDates from "../movieBooking/showDates";
import ShowTimes from "../movieBooking/theaterList";
import ScrollableTabsButtonForce from "./showTimes";

import playIcon from "../../images/index_III/icon.png";
import food from "../../images/content/fast-food.png";
import bill from "../../images/content/bill.png";
import ticket from "../../images/content/ticket.png";
import featEvent from "../../images/header/featEvent.jpg";

import queryString from "query-string";
import axios from "axios";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { List, ListItem, ListItemText } from "@material-ui/core";

import mixpanel from 'mixpanel-browser';

var movieName = [];
var Movie = "";
var movieId = 0;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export class movieBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VenuesDates: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    let movieInfo = queryString.parse(this.props.location);
    const eventId = new URLSearchParams(this.props.search).get("movieid");
    Movie = new URL(window.location.href).searchParams.get('name');
    movieId = new URL(window.location.href).searchParams.get('movieid');
    localStorage.setItem("movieName",Movie);
    localStorage.setItem("movieId",movieId);
    mixpanel.init('2b8d9434c70e8b1663153592b7d249d1', {debug: true});
    mixpanel.track(
      "Movie Detail",
      {"movie": Movie, "movieId": movieId}
    );
    // console.log(movieInfo.movieid)
    // console.log(eventId)
    this.setState(movieInfo);
    // console.log(movieInfo.name)
    movieName = movieInfo;

    // const requestOptions = {
    //   method: 'GET',
    //   headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
    // };
    // fetch(`http://sandbox-api.tickets.lk/v1/movie/${movieName.movieid}/showtime`, requestOptions)
    //     .then(response => response.json())
    //     .then((VenuesShowDates) => {
    //       console.log(VenuesShowDates.data.venueDateShowtime)
    //       this.setState({
    //         VenuesDates: VenuesShowDates.data.venueDateShowtime,
    //         dataLoaded: true,
    //        })
    //     }).catch(console.log);
    //     console.log(movieName.movieid)
  }

  render() {
    return (
      <div>
        <PrimarySearchAppBar />
        {/* <div className="prs_title_main_sec_wrapper">
  <div className="prs_title_img_overlay" />
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_title_heading_wrapper">
          <h2>Movie Booking</h2>
          <ul>
            <li><a href="#">Home</a>
            </li>
            <li>&nbsp;&nbsp; &gt;&nbsp;&nbsp; Movie Booking</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div> */}
        <div className="prs_booking_main_div_section_wrapper">
          <div className="prs_top_video_section_wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="st_video_slider_inner_wrapper float_left">
                    <div className="st_video_slider_overlay" />
                    <div className="st_video_slide_sec float_left">
                      <a
                        rel="external"
                        href=" "
                        title="Trailer"
                        className="test-popup-link"
                      >
                        <img src={playIcon} alt="img" />
                      </a>
                      <h3>{Movie}</h3>
                      <p>English, hindi, Tamil</p>
                      <h4>Action | Adventure | Fantasy</h4>
                      {/* <h5><span>2d</span> <span>3d</span> <span>D 4DX</span> <span>Imax 3D</span></h5> */}
                    </div>
                    <div className="st_video_slide_social float_left">
                      <div className="st_slider_rating_btn_heart st_slider_rating_btn_heart_5th">
                        <h5>
                          <i className="fa fa-heart" /> 85%
                        </h5>
                        <h4>52,291 votes</h4>
                      </div>
                      <div className="st_video_slide_social_left float_left">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-youtube" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="st_video_slide_social_right float_left">
                        <ul>
                          {/* <li data-animation="animated fadeInUp" className><i className="far fa-calendar-alt" /> 14 Dec, 2018</li> */}
                          {/* <li data-animation="animated fadeInUp" className><i className="far fa-clock" /> 2 hrs 23 mins</li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="st_slider_rating_main_wrapper float_left">
    <div className="container">   
      <div className="st_calender_tabs">
      </div>
    </div>
  </div> */}
          <div className="st_slider_index_sidebar_main_wrapper st_slider_index_sidebar_main_wrapper_booking float_left">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                  <div className="st_indx_slider_main_container float_left">
                    <div className="row">
                      <div className="col-md-12">
                        <ScrollableTabsButtonForce />
                        {/* <ShowTimes /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <div className="prs_mcc_left_side_wrapper">
                    <div className="prs_mcc_left_searchbar_wrapper">
                      <input type="text" placeholder="Search Movie" />
                      <button>
                        <i className="flaticon-tool" />
                      </button>
                    </div>
                    {/* <div className="prs_mcc_bro_title_wrapper">
              <h2>browse title</h2>
              <ul>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">All <span>23,124</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Action <span>512</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Romantic <span>548</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Love <span>557</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Musical <span>554</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Drama <span>567</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Thriller <span>689</span></a>
                </li>
                <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Horror <span>478</span></a>
                </li>
              </ul>
            </div> */}
                    <div className="prs_mcc_event_title_wrapper">
                      <h2>Top Events</h2>
                      {/* <img src={featEvent} alt="event_img" />
              <h3><a href="#">Music Event in Bishops</a></h3>
              <p>CMB <span><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" /><i className="fa fa-star-o" /></span>
              </p>
              <h4>June 07 <span>08:00-12:00 pm</span></h4> */}
                    </div>
                    {/* <div className="prs_mcc_event_title_wrapper">
              <h2>Top Events</h2>
              <img src={featEvent} alt="event_img" />
              <h3><a href="#">Music Event in Bishops</a></h3>
              <p>CMB <span><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" /><i className="fa fa-star-o" /></span>
              </p>
              <h4>June 07 <span>08:00-12:00 pm</span></h4>
            </div>           
            <div className="prs_mcc_event_title_wrapper">
              <h2>Top Events</h2>
              <img src={featEvent} alt="event_img" />
              <h3><a href="#">Music Event in Bishops</a></h3>
              <p>CMB <span><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-o" /><i className="fa fa-star-o" /><i className="fa fa-star-o" /></span>
              </p>
              <h4>June 07 <span>08:00-12:00 pm</span></h4>
            </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default movieBooking;
