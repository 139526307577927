import React, { Component } from "react";
import "../../css/style.css";
import "../../css/seat.css";
import TextField from "@mui/material/TextField";
import InputBase from "@material-ui/core/InputBase";
import EditIcon from "@material-ui/icons/Edit";
import Badge from "@material-ui/core/Badge";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import clsx from "clsx";

import coverImg from "../../images/header/starwar.jpg";
import userImg from "../../images/header/userPlace.jpg";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useEffect } from "react";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  nameField: {
    width: "25ch",
  },
  mobileNo: {
    width: "25ch",
  },
}));

export default function PersonalData() {
  const [value, setValue] = React.useState("male");
  const [username, setUserName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2021-08-12T21:11:54")
  );
  const [state, setState] = React.useState({
    DesktopNotifications: true,
    Promotions: false,
  });
  const classes = useStyles();

  useEffect(() => {
    setUserName(localStorage.getItem("userName"));
    setMobile(localStorage.getItem("mobileNo"));
    setEmail(localStorage.getItem("email"));
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const SwitchHandleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <label for="imageBanner">
            <input
              type="file"
              name="imageBanner"
              id="imageBanner"
              style={{ display: "none" }}
            />
            <img
              src={coverImg}
              className="banner_Image"
              width="100%"
              alt="cover"
            />
          </label>
          <label for="imageProfile">
            <input
              type="file"
              name="imageProfile"
              id="imageProfile"
              style={{ display: "none" }}
            />
            <img
              src={userImg}
              width="80px"
              style={{ position: "relative", marginTop: -80, left: 5 }}
              className="user_Image"
              alt="userImg"
            />
          </label>
        </div>
      </div>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="column"
              spacing={2}
              //   justifyContent="center"
              //   alignItems="center"
            >
              <TextField
                id="userName"
                defaultValue=""
                // className={clsx(classes.margin, classes.nameField)}
                variant="standard"
                value={username}
                disabled
              />
              <TextField
                id="email"
                defaultValue=""
                // className={clsx(classes.margin, classes.nameField)}
                variant="standard"
                value={email}
                disabled
              />
              <TextField
                id="mobileNo"
                // className={clsx(classes.margin, classes.mobileNo)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+94</InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 9,
                }}
                defaultValue="XXX XXX XXX "
                variant="standard"
                value={mobile}
                disabled
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <div style={{ padding: "10px" }}>
        {" "}
        <br />
        {/* <h4>Gender</h4> */}
        <FormControl component="fieldset">
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup
            row
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChange}
            style={{ paddingLeft: 15 }}
          >
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
      </div>

      <div style={{ padding: "10px" }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Birthday</FormLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              {/* <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        // label="Date picker inline"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        /> */}
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                // label="Date picker dialog"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </FormControl>
      </div>
      <br />
      <div style={{ padding: "10px" }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Notifications</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={state.DesktopNotifications}
                  onChange={SwitchHandleChange}
                  name="DesktopNotifications"
                />
              }
              label="Desktop Notifications"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={state.Promotions}
                  onChange={SwitchHandleChange}
                  name="Promotions"
                />
              }
              label="Promotions"
            />
          </FormGroup>
          <FormHelperText>TicketsLK</FormHelperText>
        </FormControl>
      </div>
    </div>
  );
}
