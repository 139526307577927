import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

export default function EventsList() {
  const [eventList, setEventList] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  useEffect(() => {
    const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/online?countryId=1&eventType=1`;
    const requestOptions = (token) => {
      return {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          client_token: "ebd86470-7e90-4ece-9e89-1b6d4d2cbb61",
        },
      };
    };
    const fetchData = async () => {
      try {
        const response = await fetch(url, requestOptions());
        const json = await response.json();
        // console.log(json);
        // console.log(json.data.venueDateShowtime)
        await setEventList(json.data.movies);
        setDataLoaded(true);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  if (!dataLoaded) {
    return (
      <div>
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  } else {
    return (
      <div>
        {eventList.map((evntList) => (
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="prs_feature_img_box_wrapper prs_ec_ue_inner_feat_wrapper">
              <div className="prs_feature_img">
                <img src={evntList.poster} alt="feature_img" />
                <div className="prs_ft_btn_wrapper">
                  <ul>
                    <li>
                      <a href="#">
                        <Link
                          to={
                            `/EventDetails?eventId=` +
                            evntList.id +
                            `&name=` +
                            evntList.name +
                            `&summary=` +
                            evntList.summary +
                            `&tagLine=` +
                            evntList.tagLine
                          }
                        >
                          Book Now
                        </Link>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prs_feature_img_cont">
                <h2>
                  <a href="#">
                    <Link                      
                      to={
                        `/EventDetails?eventId=` +
                        evntList.id +
                        `&name=` +
                        evntList.name +
                        `&summary=` +
                        evntList.summary +
                        `&tagLine=` +
                        evntList.tagLine
                      }
                    >
                      {evntList.name}
                    </Link>
                  </a>
                </h2>
                {evntList.venues.map((evntVenue) => (
                  <>
                    <div className="prs_ft_small_cont_left">
                      <small>{evntVenue.venue}</small>
                      <p style={{ fontSize: "12px" }}>{evntVenue.startDate}</p>
                    </div>
                    {/* <div className="prs_ft_small_cont_right">
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star-o" />
                      <i className="fa fa-star-o" />
                    </div> */}
                    {/* <div>
                      <ul>
                        <li>
                          <small>{evntVenue.startDate}</small>
                        </li>
                      </ul>
                    </div> */}
                  </>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
