import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';

const NEWS = [
    {
        title: "Venom 2: Film Review - Makes A Refreshing Spl...", 
        date: "14 December", 
        banner: "https://static0.cbrimages.com/wordpress/wp-content/uploads/2021/06/Venom-Let-there-be-carnage-GIF-Stickers-Header.jpg?q=50&fit=crop&w=960&h=500&dpr=1.5"
    },
    {
        title: "Here's Proof That the Hype Is Real for Tom ...", 
        date: "14 December", 
        banner: "https://static3.srcdn.com/wordpress/wp-content/uploads/2021/06/Tom-Hardy-as-Eddie-Brock-in-Venom-Let-There-Be-Carnage.jpg?q=50&fit=crop&w=960&h=500&dpr=1.5"
    },
    {
        title: "The Epic Cast of Venom 2 Will Definitely Make...", 
        date: "14 December", 
        banner: "https://wegotthiscovered.com/wp-content/uploads/2021/05/162006993393956392-29-640x321.jpeg"
    },
]


const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    lessTxt: {
        width: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
  });
  
  export default function TrendingNews() {
    const classes = useStyles();
    const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    }
  
    return (

<OwlCarousel className='owl-theme' loop center margin={1} autoplay ={true} dots={false} items={3} 
touchDrag={true} lazyLoad={true} animateOut={'fadeOut'}
responsive={state.responsive}>
    {NEWS.map((newsList) => {
        return (
            <Card className={classes.root} variant="outlined">
                <CardActionArea>
                    <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    height="140"
                    image={newsList.banner}
                    title="Contemplative Reptile"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h6" component="h6" className={classes.lessTxt}>
                        {newsList.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                        across all continents except Antarctica
                    </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <IconButton aria-label="add to favorites" color="secondary">
                    <ShareIcon  />
                    </IconButton>
                    <Button size="small" color="primary">
                    More
                    </Button>
                </CardActions>
            </Card> 
        );
    })}
</OwlCarousel>
    );
  }