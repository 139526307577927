import React, { Component } from "react";
import Footer from "../../components/footer/footer";
import PrimarySearchAppBar from "../../components/navBar/navBar";
import TopEventSlider from "./topEventsSlider";
import Partners from "../partners/partners";
import UpcomingFeaturedEvent from "./featuredEvent";
import EventsList from "./eventsList";

export class event extends Component {
  render() {
    return (
      <div>
        <PrimarySearchAppBar />
        <div className="prs_ec_ue_main_section_wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="prs_heading_section_wrapper">
                  <h2>Upcoming Events</h2>
                </div>
              </div>
              {/* <UpcomingFeaturedEvent/> */}
              <EventsList />
            </div>
          </div>
        </div>
        <div className="prs_ec_pe_slider_main_wrapper">
          <div className="prs_ec_pe_slider_img_overlay" />
          <div className="container">
            <div className="row">
{/*
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="prs_ec_pe_heading_section_wrapper">
                  <h2>FEATURED EVENTS</h2>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="prs_feature_slider_wrapper">
                  <TopEventSlider />
                </div>
              </div>
*/}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default event;
