import React, { useEffect, useState } from "react";
import "../../css/style.css";
import "../../css/seat.css";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "@mui/material/Tooltip";
import placeholder from "../../images/header/placeholder.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ButtonMui from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import notFound from "../../images/header/404.png";
import { set } from "date-fns";

export default function HomeMovieSlider() {
  let navigate = useNavigate();

  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const [onlineMovies, setOnineMovies] = useState([]);
  const [filterdMovie, setFileredMovies] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [movieCount, setMovieCount] = useState("");
  const [movieType, setMovieType] = useState("1");
  const [open, setOpen] = useState(false);
  const [upCome, setUpcome] = useState([]);
  const [nowShowing, setNowShowing] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/online?countryId=1&eventType=0`;
    const requestOptions = (token) => {
      return {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          client_token: "ebd86470-7e90-4ece-9e89-1b6d4d2cbb61",
        },
      };
    };
    const fetchData = async () => {
      try {
        const response = await fetch(url, requestOptions());
        const json = await response.json();
        // console.log(json);
        // console.log(json.data.venueDateShowtime)
        await setOnineMovies(json.data.movies);
        setFileredMovies(json.data.movies);
        let upcome = [];
        let nowshow = [];
        json.data.movies.forEach((element) => {
          if (element.upcoming) {
            upcome.push(element);
          } else {
            nowshow.push(element);
          }
        });
        setUpcome(upcome);
        setNowShowing(nowshow);
        setDataLoaded(true);
        // console.log(onlineMovies);
        if (json.data.movies.length > 3) {
          setMovieCount(true);
        } else {
          setMovieCount(false);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  const viewMovie = (movieData) => {
    if (movieData.bookingOpen) {
      localStorage.setItem("movieName", movieData.name);
      localStorage.setItem("movieId", movieData.id);
      navigate(`/movieBooking?movieid=${movieData.id}&name=${movieData.name}`);
    } else {
      setOpen(true);
    }
  };

  const viewMovieDetails = (MovieData) => {
    if (MovieData.bookingOpen){
      if (MovieData.bookingOpen) {
        localStorage.setItem("movieName", MovieData.name);
        localStorage.setItem("movieId", MovieData.id);
        navigate(`/MovieDetail?movieid=${MovieData.id}&name=${MovieData.name}`);
      } else {
        setOpen(true);
      }
    }
  }

  const movieTypeChange = (e) => {
    const value = e.target.value;
    if (value === "1") {
      setFileredMovies(onlineMovies);
    } else if (value === "2") {
      setFileredMovies(nowShowing);
    } else if (value === "3"){
      setFileredMovies(upCome);
    }
    setMovieType(value);
  };

  const handleClose = () => {
    setOpen(false);
    setMovieType("1");
    setFileredMovies(onlineMovies);
  };

  if (!dataLoaded) {
    return (
      <div>
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  } else {
    return (
      <div>
        {/* <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={movieType}
            onChange={movieTypeChange}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="error" />}
              label="All"
            />
            <FormControlLabel
              value="2"
              control={<Radio color="error" />}
              label="Now Showing"
            />
            <FormControlLabel
              value="3"
              control={<Radio color="error" />}
              label="Upcoming"
            />
          </RadioGroup>
        </FormControl> */}
        {onlineMovies.length > 0 ? (
          <OwlCarousel
            className="owl-theme"
            loop={movieCount}
            center={movieCount}
            margin={1}
            autoplay={true}
            dots={false}
            items={3}
            touchDrag={true}
            lazyLoad={true}
            responsive={state.responsive}
            // responsive={"0:{items:1,},600:{items:3,},1000:{items:5,}"}
            animateOut={"fadeOut"}
          >
            {onlineMovies.map((movieBannertop, idx) => {
              return (
                <div key={idx}>
                  <div className="item">
                    <div className="prs_upcom_movie_box_wrapper">
                      <div className="prs_upcom_movie_img_box">
                        <img
                          src={
                            movieBannertop.thumbnail
                            // HTMLImageElement.complete
                            //   ?
                            // `${process.env.REACT_APP_DEV_BASE_URL_IMAGE}/movie/${movieBannertop.id}/thumbnail.jpg`
                            // : placeholder
                          }
                          alt="movie_img"
                        />
                        <div className="prs_upcom_movie_img_overlay"></div>
                        <div className="prs_upcom_movie_img_btn_wrapper">
                          <ul>
                            <li>
                              {movieBannertop.bookingOpen ? (
                                <a
                                  href
                                  // onClick={() => viewMovieDetails(movieBannertop)}
                                  onClick={() => viewMovie(movieBannertop)}
                                  style={{ textDecoration: "none" }}
                                >
                                  More Info
                                </a>
                              ) : null}
                            </li>
                            <li>
                              {!movieBannertop.upcoming ? (
                                <a
                                  href
                                  onClick={() => viewMovie(movieBannertop)}
                                  style={{ textDecoration: "none" }}
                                >
                                  Now Showing
                                </a>
                              ) : (
                                <a
                                  href
                                  style={{
                                    textDecoration: "none",
                                    backgroundColor: "#2488ed",
                                  }}
                                  onClick={() => viewMovie(movieBannertop)}
                                >
                                  Coming Soon
                                </a>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="prs_upcom_movie_content_box">
                        <div className="prs_upcom_movie_content_box_inner">
                          <Tooltip title={movieBannertop.name}>
                            <h2>
                              <a
                                href
                                onClick={() => viewMovie(movieBannertop)}
                                style={{ textDecoration: "none" }}
                              >
                                {" "}
                                {movieBannertop.name.length > 10
                                  ? `${movieBannertop.name.substring(0, 10)}...`
                                  : movieBannertop.name}
                              </a>
                            </h2>
                          </Tooltip>
                          <p>{movieBannertop.genre}</p>
                        </div>
                        <div className="prs_upcom_movie_content_box_inner_icon">
                          {/* <ul>
                        <li>
                          <a href="movie_booking.html">
                            <i className="flaticon-cart-of-ecommerce"></i>
                          </a>
                        </li>
                      </ul> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        ) : (
          <>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <img src={notFound} alt="" width="50%" />
              <h4>No Movies Found</h4>
            </Stack>
          </>
        )}
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></div>
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
          style={{ marginLeft: 35 }}
        >
          <div className="prs_animate_btn1 prs_upcom_main_wrapper prs_third_slider_btn">
            <ul>
              <li data-animation="animated fadeInUp">
                <a
                  href="/movies"
                  className="button button--tamaya prs_upcom_main_btn"
                  data-text="MORE"
                  style={{ textDecoration: "none" }}
                >
                  <span>MORE</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"></div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Booking not open"}
          </DialogTitle>
          <DialogContent>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner animation="grow" variant="danger" />
              <DialogContentText id="alert-dialog-description">
                Bookings not yet open for this Movie
              </DialogContentText>
            </Stack>
          </DialogContent>
          <DialogActions>
            <ButtonMui
              onClick={handleClose}
              autoFocus
              color="error"
              variant="contained"
            >
              OK
            </ButtonMui>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
