import React ,{ Component } from 'react';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { List, ListItem, ListItemText } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import queryString from 'query-string';
var movieName = []

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper
    }
  }));

export default class ShowTimeSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
          VenuesDates: [],
          dataLoaded: false,
        };
      }
    
    
    componentDidMount() {
        let movieInfo = queryString.parse(this.props.location.search)
            console.log(movieInfo.movieid) 
            this.setState(movieInfo)
            console.log(movieInfo.name) 
         
        movieName = movieInfo
    
    const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
        };
    fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/${movieName.movieid}/showtime`, requestOptions)
            .then(response => response.json())
            .then((VenuesShowDates) => {
              console.log(VenuesShowDates.data.venueDateShowtime)
              this.setState({ 
                VenuesDates: VenuesShowDates.data.venueDateShowtime,
                dataLoaded: true,
               })
            }).catch(console.log);
            console.log(movieName.movieid)
               
          }



}

