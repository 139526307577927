import React, { Component } from 'react'
import "../../css/style.css";
import "../../css/seat.css";

import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));


export default function FAQ() {
    
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>What is <b>TicketsLK</b></Typography>
              {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography><small>
              TicketsLK is the definitive website dedicated in providing you with the convenience of booking tickets online. 
              TicketsLK offers consumers the facility of reserving and purchasing tickets for movies 
              and numerous events featured on the site. The service is rich with user-friendly interface, 
              secure payment system, 24/7 offline support which helps and safeguards its customers.
              </small>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>How can I get benefitted</Typography>
              {/* <Typography className={classes.secondaryHeading}>
                You are currently not an owner
              </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography><small>
                You need to register with TicketsLK in order to receive the wide range of services provided by the website.
                Members -Members can view all available information on movies, parties, concerts and dramas and purchase
                tickets to movies and events featured on the site.
                Non-members - Non-Members can only view the general information on movies, parties and other events.
                Event Organizers – Get details on current events on the site and gather information if they wish to 
                publish their events on the site.</small>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography className={classes.heading}>How do I become a member</Typography>
              {/* <Typography className={classes.secondaryHeading}>
                Filtering has been entirely disabled for whole web server
              </Typography> */}
            </AccordionSummary>
            <AccordionDetails>
              <Typography><small>
                <b>Step 1</b>	Click the ‘Register’ button on the top right hand corner of the TicketsLK Home Page.<br/>
                <b>Step 2</b>	Fill the registration form and ‘Submit’ form.<br/>
                <b>Step 3</b>	Activate your account by either clicking the link sent to your email or submit the code sent to your mobile as you prefer<br/>
                <b>Step 4</b>	Now you can ‘Login’ by clicking the ‘Login’ button next to the ‘Register’ button on the home page</small>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={classes.heading}>I have logged in, what do I do now?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography><small>
                You can view information on the latest movies on theaters or seminars or most happening events on town<br/>
                You can purchase Movies, Seminars or Event tickets published on the site<br/>
                You are able to inform your friends about the movies and events published on the website</small>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    
}