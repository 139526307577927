import React, { Component } from 'react'
import SideNavBar from '../../components/SideNavBar/SideNavBar';
import Preloader from '../../components/preloader/preloader';
import Footer from '../../components/footer/footer';

export class blog extends Component {
    render() {
        return (
            <div>
                    <SideNavBar />
                    <div className="prs_title_main_sec_wrapper">
  <div className="prs_title_img_overlay" />
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_title_heading_wrapper">
          <h2>Blog categories</h2>
          <ul>
            <li><a href="/">Home</a>
            </li>
            <li>&nbsp;&nbsp; &gt;&nbsp;&nbsp; Blog</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="hs_blog_categories_main_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <div className="hs_blog_left_sidebar_main_wrapper">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="hs_blog_box1_main_wrapper">
                <div className="hs_blog_box1_img_wrapper">
                  <img src="images/content/blog_category/b1.jpg" alt="blog_img" />
                </div>
                <div className="hs_blog_box1_cont_main_wrapper">
                  <div className="hs_blog_cont_heading_wrapper">
                    <ul>
                      <li>March 07, 2018</li>
                      <li>by Admin</li>
                    </ul>
                    <h2>Simplicity is about subtracting the obvious and adding part area of
                      the meaningful</h2>
                    <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit sequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet the mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio.</p>
                    <h5><a href="#">Read More <i className="fa fa-long-arrow-right" /></a></h5>
                  </div>
                </div>
                <div className="hs_blog_box1_bottom_cont_main_wrapper">
                  <div className="hs_blog_box1_bottom_cont_left">
                    <ul>
                      <li><i className="fa fa-thumbs-up" /> &nbsp;&nbsp;<a href="#">1244 Likes</a>
                      </li>
                      <li><i className="fa fa-comments" /> &nbsp;&nbsp;<a href="#">256 Comments</a>
                      </li>
                      <li><i className="fa fa-tags" /> &nbsp;&nbsp;<a href="#">Presenter Movie</a>
                      </li>
                    </ul>
                  </div>
                  <div className="hs_blog_box1_bottom_cont_right">
                    <ul>
                      <li><a href="#"><i className="fa fa-facebook" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-youtube-play" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-linkedin" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-twitter" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="hs_blog_box1_main_wrapper hs_blog_box2_main_wrapper">
                <div className="hs_blog_box1_img_wrapper">
                  <div className="owl-carousel owl-theme owl-loaded owl-drag">
                    <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-1736px, 0px, 0px)', transition: 'all 1.2s ease 0s', width: 6076}}><div className="owl-item cloned" style={{width: 848, marginRight: 20}}><div className="item">
                            <img src="images/content/blog_category/b2.jpg" alt="blog_img" />
                          </div></div><div className="owl-item cloned" style={{width: 848, marginRight: 20}}><div className="item">
                            <img src="images/content/blog_category/b3.jpg" alt="blog_img" />
                          </div></div><div className="owl-item active" style={{width: 848, marginRight: 20}}><div className="item">
                            <img src="images/content/blog_category/b1.jpg" alt="blog_img" />
                          </div></div><div className="owl-item" style={{width: 848, marginRight: 20}}><div className="item">
                            <img src="images/content/blog_category/b2.jpg" alt="blog_img" />
                          </div></div><div className="owl-item" style={{width: 848, marginRight: 20}}><div className="item">
                            <img src="images/content/blog_category/b3.jpg" alt="blog_img" />
                          </div></div><div className="owl-item cloned" style={{width: 848, marginRight: 20}}><div className="item">
                            <img src="images/content/blog_category/b1.jpg" alt="blog_img" />
                          </div></div><div className="owl-item cloned" style={{width: 848, marginRight: 20}}><div className="item">
                            <img src="images/content/blog_category/b2.jpg" alt="blog_img" />
                          </div></div></div></div><div className="owl-nav"><div className="owl-prev"><i className="flaticon-play-button" /></div><div className="owl-next"><i className="flaticon-play-button" /></div></div><div className="owl-dots"><div className="owl-dot active"><span /></div><div className="owl-dot"><span /></div><div className="owl-dot"><span /></div></div></div>
                </div>
                <div className="hs_blog_box1_cont_main_wrapper">
                  <div className="hs_blog_cont_heading_wrapper">
                    <ul>
                      <li>March 07, 2018</li>
                      <li>by Admin</li>
                    </ul>
                    <h2>Simplicity is about subtracting the obvious and adding part area of
                      the meaningful</h2>
                    <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit sequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet the mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio.</p>
                    <h5><a href="#">Read More <i className="fa fa-long-arrow-right" /></a></h5>
                  </div>
                </div>
                <div className="hs_blog_box1_bottom_cont_main_wrapper">
                  <div className="hs_blog_box1_bottom_cont_left">
                    <ul>
                      <li><i className="fa fa-thumbs-up" /> &nbsp;&nbsp;<a href="#">1244 Likes</a>
                      </li>
                      <li><i className="fa fa-comments" /> &nbsp;&nbsp;<a href="#">256 Comments</a>
                      </li>
                      <li><i className="fa fa-tags" /> &nbsp;&nbsp;<a href="#">Presenter Movie</a>
                      </li>
                    </ul>
                  </div>
                  <div className="hs_blog_box1_bottom_cont_right">
                    <ul>
                      <li><a href="#"><i className="fa fa-facebook" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-youtube-play" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-linkedin" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-twitter" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="hs_blog_box1_main_wrapper hs_blog_box2_main_wrapper">
                <div className="hs_blog_box1_img_wrapper">
                  <img src="images/content/blog_category/b3.jpg" alt="blog_img" />
                  <div className="prs_ms_scene_img_overlay">
                    <ul>
                      <li><a className="test-popup-link button" rel="external" href="https://www.youtube.com/embed/ryzOXAO0Ss0" title="title"><i className="flaticon-play-button" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="hs_blog_box1_cont_main_wrapper">
                  <div className="hs_blog_cont_heading_wrapper">
                    <ul>
                      <li>March 07, 2018</li>
                      <li>by Admin</li>
                    </ul>
                    <h2>Simplicity is about subtracting the obvious and adding part area of the meaningful</h2>
                    <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit sequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet the mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio.</p>
                    <h5><a href="#">Read More <i className="fa fa-long-arrow-right" /></a></h5>
                  </div>
                </div>
                <div className="hs_blog_box1_bottom_cont_main_wrapper">
                  <div className="hs_blog_box1_bottom_cont_left">
                    <ul>
                      <li><i className="fa fa-thumbs-up" /> &nbsp;&nbsp;<a href="#">1244 Likes</a>
                      </li>
                      <li><i className="fa fa-comments" /> &nbsp;&nbsp;<a href="#">256 Comments</a>
                      </li>
                      <li><i className="fa fa-tags" /> &nbsp;&nbsp;<a href="#">Presenter Movie</a>
                      </li>
                    </ul>
                  </div>
                  <div className="hs_blog_box1_bottom_cont_right">
                    <ul>
                      <li><a href="#"><i className="fa fa-facebook" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-youtube-play" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-linkedin" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-twitter" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="hs_blog_box1_main_wrapper hs_blog_box2_main_wrapper">
                <div className="hs_blog_box1_img_wrapper">
                  <iframe src="https://www.youtube.com/embed/tqYDVMTjAzY" />
                </div>
                <div className="hs_blog_box1_cont_main_wrapper">
                  <div className="hs_blog_cont_heading_wrapper">
                    <ul>
                      <li>March 07, 2018</li>
                      <li>by Admin</li>
                    </ul>
                    <h2>Simplicity is about subtracting the obvious and adding part area of
                      the meaningful</h2>
                    <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit sequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet the mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio.</p>
                    <h5><a href="#">Read More <i className="fa fa-long-arrow-right" /></a></h5>
                  </div>
                </div>
                <div className="hs_blog_box1_bottom_cont_main_wrapper">
                  <div className="hs_blog_box1_bottom_cont_left">
                    <ul>
                      <li><i className="fa fa-thumbs-up" /> &nbsp;&nbsp;<a href="#">1244 Likes</a>
                      </li>
                      <li><i className="fa fa-comments" /> &nbsp;&nbsp;<a href="#">256 Comments</a>
                      </li>
                      <li><i className="fa fa-tags" /> &nbsp;&nbsp;<a href="#">Presenter Movie</a>
                      </li>
                    </ul>
                  </div>
                  <div className="hs_blog_box1_bottom_cont_right">
                    <ul>
                      <li><a href="#"><i className="fa fa-facebook" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-youtube-play" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-linkedin" /></a>
                      </li>
                      <li><a href="#"><i className="fa fa-twitter" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="pager_wrapper prs_blog_pagi_wrapper">
                <ul className="pagination">
                  <li><a href="#"><i className="flaticon-left-arrow" /></a>
                  </li>
                  <li className="btc_shop_pagi"><a href="#">01</a>
                  </li>
                  <li className="btc_shop_pagi"><a href="#">02</a>
                  </li>
                  <li className="btc_third_pegi btc_shop_pagi"><a href="#">03</a>
                  </li>
                  <li className="hidden-xs btc_shop_pagi"><a href="#">04</a>
                  </li>
                  <li><a href="#"><i className="flaticon-right-arrow" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <div className="hs_blog_right_sidebar_main_wrapper">
          <div className="prs_mcc_left_searchbar_wrapper">
            <input type="text" placeholder="Search Movie" />
            <button><i className="flaticon-tool" />
            </button>
          </div>
          <div className="prs_bc_right_about_wrapper">
            <img src="images/content/blog_category/side_img1.jpg" alt="side_img" />
            <h2>About Presenter</h2>
            <p>Lorem ipsum dolor sit amet ue adipisicing elit, sed do eiuodor incididunt ut part.</p>
            <h5><a href="#">Read More <i className="fa fa-long-arrow-right" /></a></h5>
          </div>
          <div className="prs_mcc_bro_title_wrapper">
            <h2>Category</h2>
            <ul>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">All <span>23,124</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Action <span>512</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Romantic <span>548</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Love <span>557</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Musical <span>554</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Drama <span>567</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Thriller <span>689</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">Horror <span>478</span></a>
              </li>
            </ul>
          </div>
          <div className="prs_mcc_bro_title_wrapper">
            <h2>Recent News</h2>
            <div className="hs_blog_right_recnt_cont_wrapper">
              <div className="hs_footer_ln_img_wrapper">
                <img src="images/content/blog_category/n1.jpg" alt="ln_img" />
              </div>
              <div className="hs_footer_ln_cont_wrapper">
                <h4>Lorem spum
                  menus.</h4>
                <p>12 May 2018</p>
              </div>
            </div>
            <div className="hs_blog_right_recnt_cont_wrapper hs_blog_right_recnt_cont_wrapper2">
              <div className="hs_footer_ln_img_wrapper">
                <img src="images/content/blog_category/n2.jpg" alt="ln_img" />
              </div>
              <div className="hs_footer_ln_cont_wrapper">
                <h4>Lorem spum
                  menus.</h4>
                <p>12 May 2018</p>
              </div>
            </div>
            <div className="hs_blog_right_recnt_cont_wrapper hs_blog_right_recnt_cont_wrapper2">
              <div className="hs_footer_ln_img_wrapper">
                <img src="images/content/blog_category/n3.jpg" alt="ln_img" />
              </div>
              <div className="hs_footer_ln_cont_wrapper">
                <h4>Lorem spum
                  menus.</h4>
                <p>12 May 2018</p>
              </div>
            </div>
          </div>
          <div className="prs_mcc_bro_title_wrapper">
            <h2>Archives</h2>
            <ul>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">2012 <span>23,124</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">2013 <span>512</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">2014 <span>548</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">2015 <span>557</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">2016 <span>554</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">2017 <span>567</span></a>
              </li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp;&nbsp;<a href="#">2018 <span>689</span></a>
              </li>
            </ul>
          </div>
          <div className="prs_blog_right_sub_btn_wrapper">
            <h2>Subscribe</h2>
            <input type="text" placeholder="Your email id" />
            <ul>
              <li><a href="#">Subscribe</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

                    <Footer />
            </div>
        )
    }
}

export default blog
