import React, { useEffect } from "react";
import "../../css/style.css";
import "../../css/seat.css";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@mui/material/Tooltip";
import placeholder from "../../images/header/placeholder.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HomeEventSlider() {
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  let navigate = useNavigate();

  const [onlineMovies, setOnineMovies] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [movie, setMovie] = React.useState([]);
  const [movieCount, setMovieCount] = React.useState("");

  useEffect(() => {
    const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/online?countryId=1&eventType=1`;
    const requestOptions = (token) => {
      return {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          client_token: "ebd86470-7e90-4ece-9e89-1b6d4d2cbb61",
        },
      };
    };
    const fetchData = async () => {
      try {
        const response = await fetch(url, requestOptions());
        const json = await response.json();
        // console.log(json);
        // console.log(json.data.venueDateShowtime)
        await setOnineMovies(json.data.movies);
        setDataLoaded(true);
        if (json.data.movies.length > 3) {
          setMovieCount(true);
        } else {
          setMovieCount(false);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  const handleClickOpen = (movieBannertop) => {
    if (movieBannertop.restricted === true) {
      setMovie(movieBannertop);
      setDialogOpen(true);
    } else {
      localStorage.setItem("eventName", movieBannertop.name);
      localStorage.setItem("eventId", movieBannertop.id);
      navigate(
        `/EventDetails?eventId=${movieBannertop.id}&name=${movieBannertop.name}`
      );
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleVerify = () => {
    const cc = "BEYONDTHEPEDALS";
    if (code === cc) {
      navigate.push({
        pathname: "/EventDetails",
        search: `?movieid=${movie.id}&name=${movie.name}`,
      });
    } else {
      alert("Invalid Code");
    }
  };

  const handleBook = (movieBannertop) => {
    navigate.push({
      pathname: "/EventDetails",
      search: `?movieid=${movieBannertop.id}&name=${movieBannertop.name}`,
    });
  };

  const setAccessCode = (e) => {
    setCode(e.target.value);
  };

  if (!dataLoaded) {
    return (
      <div>
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="danger" />
      </div>
    );
  } else {
    return (
      <div>
        <OwlCarousel
          className="owl-theme"
          loop={movieCount}
          center={movieCount}
          margin={1}
          autoplay={true}
          dots={false}
          touchDrag={true}
          lazyLoad={true}
          responsive={state.responsive}
          // responsive={"0:{items:1,},600:{items:3,},1000:{items:5,}"}
          animateOut={"fadeOut"}
        >
          {onlineMovies.map((movieBannertop, idx) => {
            return (
              <div key={idx}>
                {/* <Card style={{ width: "250px" }}>
                  <Card.Img
                    variant="top"
                    src={
                      HTMLImageElement.complete
                        ? `${process.env.REACT_APP_DEV_BASE_URL_IMAGE}/event/${movieBannertop.id}/thumbnail.jpg`
                        : placeholder
                    }
                    height="380"
                    max-width="100% !important"
                    alt={movieBannertop.name}
                  />
                  <Card.Body>
                    <Tooltip title={movieBannertop.name}>
                      <Card.Title as="h6" style={{ wordBreak: "break-all" }}>
                        <b>
                          {movieBannertop.name.length > 20
                            ? `${movieBannertop.name.substring(0, 20)}...`
                            : movieBannertop.name}
                        </b>
                      </Card.Title>
                    </Tooltip>
                    <Card.Subtitle className="mb-2 text-muted">
                      {movieBannertop.genre}
                    </Card.Subtitle>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <Button
                      variant="danger"
                      style={{ backgroundColor: "#ff4444" }}
                      onClick={() => handleClickOpen(movieBannertop)}
                    >
                      Book Now
                    </Button>
                  </Card.Footer>
                </Card> */}
                <div className="item">
                  <div className="prs_upcom_movie_box_wrapper">
                    <div className="prs_upcom_movie_img_box">
                      <img
                        src={
                          // HTMLImageElement.complete
                            // ?
                             `${process.env.REACT_APP_DEV_BASE_URL_IMAGE}/event/${movieBannertop.id}/thumbnail.jpg`
                            // : placeholder
                        }
                        alt="movie_img"
                      />
                      <div className="prs_upcom_movie_img_overlay"></div>
                      <div className="prs_upcom_movie_img_btn_wrapper">
                        <ul>
                          <li>
                            <a
                              href=" "
                              onClick={() => handleClickOpen(movieBannertop)}
                              style={{ textDecoration: "none" }}
                            >
                              More Info
                            </a>
                          </li>
                          <li>
                            <a
                              href=" "
                              onClick={() => handleClickOpen(movieBannertop)}
                              style={{ textDecoration: "none" }}
                            >
                              Book Now
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="prs_upcom_movie_content_box">
                      <div className="prs_upcom_movie_content_box_inner">
                      <Tooltip title={movieBannertop.name}>
                        <h2>
                          <a
                            href=" "
                            onClick={() => handleClickOpen(movieBannertop)}
                            style={{ textDecoration: "none" }}
                          >
                            {" "}
                            {movieBannertop.name.length > 20
                              ? `${movieBannertop.name.substring(0, 15)}...`
                              : movieBannertop.name}
                          </a>
                        </h2>
                        </Tooltip>
                        <p>{movieBannertop.genre}</p>
                      </div>
                      <div className="prs_upcom_movie_content_box_inner_icon">
                        {/* <ul>
                          <li>
                            <a href="movie_booking.html">
                              <i className="flaticon-cart-of-ecommerce"></i>
                            </a>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></div>
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
          style={{ marginLeft: 35 }}
        >
          <div className="prs_animate_btn1 prs_upcom_main_wrapper prs_third_slider_btn">
            <ul>
              <li data-animation="animated fadeInUp">
                <a
                  href="/events"
                  className="button button--tamaya prs_upcom_main_btn"
                  data-text="MORE"
                  style={{ textDecoration: "none" }}
                >
                  <span>MORE</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"></div>

        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle id="form-dialog-title">PRIVATE EVENT</DialogTitle>
          <DialogContent>
            <DialogContentText>Please enter your Access Code</DialogContentText>
            <TextField
              required
              autoFocus
              margin="dense"
              id="token"
              label="Access Code"
              type="text"
              value={code}
              onChange={setAccessCode}
            />
            <br />
            <Button variant="danger" onClick={handleVerify}>
              Verify
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
