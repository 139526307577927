import React, { Component } from 'react'
import SideNavBar from '../../components/SideNavBar/SideNavBar';
import Preloader from '../../components/preloader/preloader';
import Footer from '../../components/footer/footer';

export class aboutUs extends Component {
    render() {
        return (
            <div>
                  <SideNavBar />
                  <div className="prs_title_main_sec_wrapper">
  <div className="prs_title_img_overlay" />
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_title_heading_wrapper">
          <h2>about us</h2>
          <ul>
            <li><a href="#">Home</a>
            </li>
            <li>&nbsp;&nbsp; &gt;&nbsp;&nbsp; About us</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="prs_about_presenter_main_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_heading_section_wrapper">
          <h2>About Presenter</h2>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div className="prs_about_pre_left_sec_wrapper">
          <div className="prs_ap_left_img_wrapper">
            <img src="images/content/about/ap1.jpg" alt="about_img" />
          </div>
          <div className="prs_ap_left_img_cont_wrapper">
            <h2>about our journey</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor nt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerciton ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in it in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaeat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est labonm. Sed ut perspiciatis unde omnis
              <br />
              <br />iste natus error sit voluptatem accusantium doloremque laudantium, totam reme ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt emo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia tur magni dolores eos qui ratione voluptatem.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div className="prs_about_pre_right_sec_wrapper">
          <div className="prs_about_pre_right_slider_sec_wrapper">
            <div className="owl-carousel owl-theme owl-loaded owl-drag">
              <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-2300px, 0px, 0px)', transition: 'all 1.2s ease 0s', width: 4025}}><div className="owl-item cloned" style={{width: 555, marginRight: 20}}><div className="item">
                      <img src="images/content/about/ap2.jpg" alt="about_img" />
                    </div></div><div className="owl-item cloned" style={{width: 555, marginRight: 20}}><div className="item">
                      <img src="images/content/about/ap2.jpg" alt="about_img" />
                    </div></div><div className="owl-item" style={{width: 555, marginRight: 20}}><div className="item">
                      <img src="images/content/about/ap2.jpg" alt="about_img" />
                    </div></div><div className="owl-item" style={{width: 555, marginRight: 20}}><div className="item">
                      <img src="images/content/about/ap2.jpg" alt="about_img" />
                    </div></div><div className="owl-item active" style={{width: 555, marginRight: 20}}><div className="item">
                      <img src="images/content/about/ap2.jpg" alt="about_img" />
                    </div></div><div className="owl-item cloned" style={{width: 555, marginRight: 20}}><div className="item">
                      <img src="images/content/about/ap2.jpg" alt="about_img" />
                    </div></div><div className="owl-item cloned" style={{width: 555, marginRight: 20}}><div className="item">
                      <img src="images/content/about/ap2.jpg" alt="about_img" />
                    </div></div></div></div><div className="owl-nav"><div className="owl-prev"><i className="flaticon-play-button" /></div><div className="owl-next"><i className="flaticon-play-button" /></div></div><div className="owl-dots"><div className="owl-dot"><span /></div><div className="owl-dot"><span /></div><div className="owl-dot active"><span /></div></div></div>
          </div>
          <div className="prs_about_pre_right_slider_sec_cont_wrapper">
            <h2>Who we are</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiumod tempor nt ut labore et dolore magna aliqua. Ut enim ad minim veniams nostrud exerciton ullamco laboris nisi ut aliquip ex ea commodo consat.
              <br />
              <br />Duis aute irure dolor in it in voluptate it esscillumiore eu fugiat nulla pariatur. Excepteur sint occaeat idatat non proident, sunt in culpa qui officia deserunt mollit anim id estnm. ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolor- emque laudantium totam remquae.</p>
            <ul>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp; Movie Trailer Making</li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp; Vfs Work shop</li>
            </ul>
            <ul className="prs_ap_ul_last">
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp; Movie Trailer Making</li>
              <li><i className="fa fa-caret-right" /> &nbsp;&nbsp; Vfs Work shop</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="prs_vp_main_section_wrapper prs_about_vp_main_section_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_heading_section_wrapper">
          <h2>Video &amp; photos</h2>
        </div>
      </div>
      <div className="prs_vp_main_slider_wrapper">
        <div className="prs_vp_left_slidebar_wrapper">
          <div className="wrap-album-slider">
            <div className="bx-wrapper" style={{maxWidth: 257}}><div className="bx-viewport" style={{width: '100%', overflow: 'hidden', position: 'relative', height: 176}}><ul className="prs_vp_left_slider" style={{width: 'auto', position: 'relative', top: '-144.518px'}}>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp1.jpg" alt="vp_img" /> <a href="images/content/vp1.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp2.jpg" alt="vp_img" /> <a href="images/content/vp2.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp3.jpg" alt="vp_img" /> <a href="images/content/vp3.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp4.jpg" alt="vp_img" /> <a href="images/content/vp4.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp5.jpg" alt="vp_img" /> <a href="images/content/vp5.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp6.jpg" alt="vp_img" /> <a href="images/content/vp6.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp1.jpg" alt="vp_img" /> <a href="images/content/vp1.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp2.jpg" alt="vp_img" /> <a href="images/content/vp2.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp3.jpg" alt="vp_img" /> <a href="images/content/vp3.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp4.jpg" alt="vp_img" /> <a href="images/content/vp4.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp5.jpg" alt="vp_img" /> <a href="images/content/vp5.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp6.jpg" alt="vp_img" /> <a href="images/content/vp6.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li></ul></div></div>
          </div>
        </div>
        <div className="prs_vp_center_slidebar_wrapper">
          <div className="prs_vp_center_slider">
            <div className="owl-carousel owl-theme owl-loaded owl-drag">
              <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-3216px, 0px, 0px)', transition: 'all 1.2s ease 0s', width: 5628}}><div className="owl-item cloned" style={{width: 784, marginRight: 20}}><div className="item">
                      <div className="prs_vp_center_slider_img_wrapper">
                        <img src="images/content/vp7.jpg" alt="vp_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: 784, marginRight: 20}}><div className="item">
                      <div className="prs_vp_center_slider_img_wrapper">
                        <img src="images/content/vp7.jpg" alt="vp_img" />
                      </div>
                    </div></div><div className="owl-item" style={{width: 784, marginRight: 20}}><div className="item">
                      <div className="prs_vp_center_slider_img_wrapper">
                        <img src="images/content/vp7.jpg" alt="vp_img" />
                      </div>
                    </div></div><div className="owl-item" style={{width: 784, marginRight: 20}}><div className="item">
                      <div className="prs_vp_center_slider_img_wrapper">
                        <img src="images/content/vp7.jpg" alt="vp_img" />
                      </div>
                    </div></div><div className="owl-item active" style={{width: 784, marginRight: 20}}><div className="item">
                      <div className="prs_vp_center_slider_img_wrapper">
                        <img src="images/content/vp7.jpg" alt="vp_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: 784, marginRight: 20}}><div className="item">
                      <div className="prs_vp_center_slider_img_wrapper">
                        <img src="images/content/vp7.jpg" alt="vp_img" />
                      </div>
                    </div></div><div className="owl-item cloned" style={{width: 784, marginRight: 20}}><div className="item">
                      <div className="prs_vp_center_slider_img_wrapper">
                        <img src="images/content/vp7.jpg" alt="vp_img" />
                      </div>
                    </div></div></div></div><div className="owl-nav"><div className="owl-prev"><i className="flaticon-play-button" /></div><div className="owl-next"><i className="flaticon-play-button" /></div></div><div className="owl-dots"><div className="owl-dot"><span /></div><div className="owl-dot"><span /></div><div className="owl-dot active"><span /></div></div></div>
          </div>
        </div>
        <div className="prs_vp_right_slidebar_wrapper">
          <div className="wrap-album-slider">
            <div className="bx-wrapper" style={{maxWidth: 257}}><div className="bx-viewport" style={{width: '100%', overflow: 'hidden', position: 'relative', height: 176}}><ul className="prs_vp_right_slider" style={{width: 'auto', position: 'relative', top: '-627.945px'}}><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp6.jpg" alt="vp_img" /> <a href="images/content/vp6.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp5.jpg" alt="vp_img" /> <a href="images/content/vp5.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp4.jpg" alt="vp_img" /> <a href="images/content/vp4.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp3.jpg" alt="vp_img" /> <a href="images/content/vp3.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp2.jpg" alt="vp_img" /> <a href="images/content/vp2.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li><li className="album-slider__item prs_vp_hover_overlay bx-clone" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp1.jpg" alt="vp_img" /> <a href="images/content/vp1.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp6.jpg" alt="vp_img" /> <a href="images/content/vp6.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp5.jpg" alt="vp_img" /> <a href="images/content/vp5.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp4.jpg" alt="vp_img" /> <a href="images/content/vp4.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp3.jpg" alt="vp_img" /> <a href="images/content/vp3.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp2.jpg" alt="vp_img" /> <a href="images/content/vp2.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                  <li className="album-slider__item prs_vp_hover_overlay" style={{float: 'none', listStyle: 'none', position: 'relative', width: 176, marginBottom: 17}}>
                    <figure className="album">
                      <div className="prs_vp_img_overlay">
                        <img src="images/content/vp1.jpg" alt="vp_img" /> <a href="images/content/vp1.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="fa fa-search" /></a>
                      </div>
                    </figure>
                  </li>
                </ul></div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="prs_about_team_main_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_heading_section_wrapper">
          <h2>Director And Team</h2>
        </div>
      </div>
      <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
        <div className="prs_about_team_first_mem_wrapper">
          <div className="prs_about_first_mem_img_wrapper">
            <img src="images/content/about/tm1.jpg" alt="team_img" />
            <div className="prs_at_social_main_wrapper">
              <ul>
                <li><a href="#"><i className="fa fa-facebook" /></a>
                </li>
                <li><a href="#"><i className="fa fa-twitter" /></a>
                </li>
                <li><a href="#"><i className="fa fa-linkedin" /></a>
                </li>
                <li><a href="#"><i className="fa fa-youtube-play" /></a>
                </li>
              </ul>
            </div>
          </div>
          <div className="prs_about_first_mem_img_cont_wrapper">
            <h2><a href="#">Johan Doe</a></h2>
            <p>Director</p>
          </div>
        </div>
      </div>
      <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_ap_colum_full">
            <div className="prs_about_team_first_mem_wrapper prs_about_team_first_mem_wrapper2_inner">
              <div className="prs_about_first_mem_img_wrapper">
                <img src="images/content/about/tm2.jpg" alt="team_img" />
                <div className="prs_at_social_main_wrapper prs_at_social_inner_main_wrapper">
                  <ul>
                    <li><a href="#"><i className="fa fa-facebook" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-twitter" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-linkedin" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-youtube-play" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prs_about_first_mem_img_cont_wrapper prs_about_first_mem_img_cont_inner_wrapper">
                <h2><a href="#">akshay h.</a></h2>
                <p>Director</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_ap_colum_full">
            <div className="prs_about_team_first_mem_wrapper prs_about_team_first_mem_wrapper2_inner">
              <div className="prs_about_first_mem_img_wrapper">
                <img src="images/content/about/tm3.jpg" alt="team_img" />
                <div className="prs_at_social_main_wrapper prs_at_social_inner_main_wrapper">
                  <ul>
                    <li><a href="#"><i className="fa fa-facebook" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-twitter" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-linkedin" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-youtube-play" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prs_about_first_mem_img_cont_wrapper prs_about_first_mem_img_cont_inner_wrapper">
                <h2><a href="#">ajay S.</a></h2>
                <p>Director</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_ap_colum_full">
            <div className="prs_about_team_first_mem_wrapper prs_about_team_first_mem_wrapper2_inner">
              <div className="prs_about_first_mem_img_wrapper">
                <img src="images/content/about/tm4.jpg" alt="team_img" />
                <div className="prs_at_social_main_wrapper prs_at_social_inner_main_wrapper">
                  <ul>
                    <li><a href="#"><i className="fa fa-facebook" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-twitter" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-linkedin" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-youtube-play" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prs_about_first_mem_img_cont_wrapper prs_about_first_mem_img_cont_inner_wrapper">
                <h2><a href="#">Sandy S.</a></h2>
                <p>Director</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_ap_colum_full">
            <div className="prs_about_team_first_mem_wrapper prs_about_team_first_mem_wrapper2">
              <div className="prs_about_first_mem_img_wrapper">
                <img src="images/content/about/tm5.jpg" alt="team_img" />
                <div className="prs_at_social_main_wrapper prs_at_social_inner_main_wrapper">
                  <ul>
                    <li><a href="#"><i className="fa fa-facebook" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-twitter" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-linkedin" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-youtube-play" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prs_about_first_mem_img_cont_wrapper prs_about_first_mem_img_cont_inner_wrapper">
                <h2><a href="#">Farhan S.</a></h2>
                <p>Director</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_ap_colum_full">
            <div className="prs_about_team_first_mem_wrapper prs_about_team_first_mem_wrapper2">
              <div className="prs_about_first_mem_img_wrapper">
                <img src="images/content/about/tm6.jpg" alt="team_img" />
                <div className="prs_at_social_main_wrapper prs_at_social_inner_main_wrapper">
                  <ul>
                    <li><a href="#"><i className="fa fa-facebook" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-twitter" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-linkedin" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-youtube-play" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prs_about_first_mem_img_cont_wrapper prs_about_first_mem_img_cont_inner_wrapper">
                <h2><a href="#">Vijay p.</a></h2>
                <p>Director</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 prs_ap_colum_full">
            <div className="prs_about_team_first_mem_wrapper prs_about_team_first_mem_wrapper2">
              <div className="prs_about_first_mem_img_wrapper">
                <img src="images/content/about/tm7.jpg" alt="team_img" />
                <div className="prs_at_social_main_wrapper prs_at_social_inner_main_wrapper">
                  <ul>
                    <li><a href="#"><i className="fa fa-facebook" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-twitter" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-linkedin" /></a>
                    </li>
                    <li><a href="#"><i className="fa fa-youtube-play" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="prs_about_first_mem_img_cont_wrapper prs_about_first_mem_img_cont_inner_wrapper">
                <h2><a href="#">ajay S.</a></h2>
                <p>Director</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_animate_btn1 prs_upcom_main_wrapper prs_ap_btn_wrapper">
          <ul>
            <li><a href="#" className="button button--tamaya prs_upcom_main_btn" data-text="view more"><span>View More</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="counter_section">
  <section className="counter-section section-padding">
    <div className="container">
      <div className="row">
        <div className="trucking_counter">
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 prs_count_colum1">
            <div className="con"> <i className="fa fa-user-o" />
            </div>
            <div className="count-description"> <span className="timer">2540</span>
              <h5 className="con1">Sucess Movie</h5>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 prs_count_colum2">
            <div className="con"> <i className="fa fa-users" />
            </div>
            <div className="count-description"> <span className="timer">7325</span>
              <h5 className="con2">Happy Clents</h5>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 prs_count_colum3">
            <div className="con"> <i className="fa fa-snapchat" />
            </div>
            <div className="count-description"> <span className="timer">1924</span>
              <h5 className="con3">Awards</h5>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 prs_count_colum4">
            <div className="con"> <i className="fa  fa-thumbs-o-up" />
            </div>
            <div className="count-description"> <span className="timer">4275</span>
              <h5 className="con4">Sucess Movie</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div className="prs_patner_main_section_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_heading_section_wrapper">
          <h2>Our Patner’s</h2>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_pn_slider_wraper">
          <div className="owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-1546px, 0px, 0px)', transition: 'all 1.2s ease 0s', width: 3480}}><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p1.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p2.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p3.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p4.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p5.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p6.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p1.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p2.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p3.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p4.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p5.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p6.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p1.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p2.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p3.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p4.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p5.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p6.jpg" alt="patner_img" />
                    </div>
                  </div></div></div></div><div className="owl-nav disabled"><div className="owl-prev"><i className="flaticon-play-button" /></div><div className="owl-next"><i className="flaticon-play-button" /></div></div><div className="owl-dots disabled"><div className="owl-dot active"><span /></div></div></div>
        </div>
      </div>
    </div>
  </div>
</div>

                   <Footer />
            </div>
        )
    }
}

export default aboutUs
