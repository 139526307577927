import React, { useState, useEffect } from "react";
import '../../css/style.css';
import PrimarySearchAppBar from "../../components/navBar/navBar";
import Freddy from "./fixedSeatPlans/freddyGN";
import stage from '../../images/content/stage.png'
import { Link, useLocation, useHistory, useRouteMatch, useNavigate  } from "react-router-dom";
import Button from "@material-ui/core/Button";
import queryString from 'query-string';
import Spinner from 'react-bootstrap/Spinner'
import Search from "antd/lib/transfer/search";

var seatSelected = []
var seatNoSelected = []
var seatCatSelected = []

export default function EventSeatPlan (){

    var path = queryString.parse(useLocation().search);
    console.log(path)

    let navigate = useNavigate();

    const [checked, setChecked] = useState(false);
    const [seatDataLoad, setSeatDataLoad] =useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showTimes, setShowTimes] = useState({});
    const [showdate, setShowdate] = useState('');
    const [reservedSeats, setReservedSeats] = useState([]);
    const [nonReservedSeats, setNonReservedSeats] = useState([]);
    const [rSeat, setRseats] = useState([]);
    // const [checked, setChecked] = useState(false);
    
    useEffect(() =>{
        console.log('SSS')
        fetchData();
        console.log('QQQQ')    
      },[]);

      const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/`+ path.eventId + `/venue/`+ path.venue +`/showtime`;
      const requestOptions = (token) => {
        return ({
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
        })
      };
      const fetchData = async () => {
        try {
          const response = await fetch(url, requestOptions());
          const json =  await response.json();
          console.log('GGGGG')
          console.log(json);
          console.log(json.data.venueDateShowtime)
          setShowTimes(json.data.dateShowtimes[0].showtimes[0]);
          console.log(showTimes)
          console.log(json.data.dateShowtimes[0].date)
          setShowdate(json.data.dateShowtimes[0].date);
          const showtimeid = json.data.dateShowtimes[0].showtimes[0].showtimeId
          const showdate =  json.data.dateShowtimes[0].date           
        //   setDataLoaded(true);
          getSeatsArrangement(showtimeid,showdate);
        }
        catch (error) {
          console.log("error",error);
        }
        console.log(showdate)
        console.log(showTimes)
      };



      const getSeatsArrangement = async (showtimeid,showdate) => {
        console.log(showdate)
        console.log(showTimes)
            const requestOptions = (token) => {
                return ({
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
                })
              };
    
            try{
                const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/seat?venueId=` + path.venue + `&movieId=`+ path.eventId +`&showtimeId=${showtimeid}&movieDate=${showdate}`;
                const response = await fetch(url,requestOptions());
                const json = await response.json();
                console.log(json)
                if(json.success){
                    console.log('FF')
                    setReservedSeats(json.data.reservedSeats)
                    setNonReservedSeats(json.data.nonReservedSeats)
                    setDataLoaded(true);
                    if(reservedSeats === undefined){
                        console.log('VV')
                        fetchData();
                    }
                } else {
                    console.log('no call')
                }
            } catch(error) {
                console.log("error",error);
            }
    }

    console.log(reservedSeats)
    // let rSeat = []
    var seatlistAll = reservedSeats
    console.log(rSeat)
    console.log(nonReservedSeats)
    console.log(showTimes.showtimeId)
    console.log(showdate)
      
  
    // const handleChecked = async(e) => {
    //   let isChecked = e.target.checked;
    //   if(isChecked){
    //     seatSelected.push(e.target.name)
    //   } else {
    //     seatSelected = seatSelected.filter((name) => e.target.name !== name);
    //   }
    //   setChecked(!checked);
    //   console.log(seatSelected)
    // }

    const proceedHandleClicked = (e) => {
        e.preventDefault();
        if(seatCatSelected.length > 0){
            navigate.push({
            pathname:'/eventbookingsummary',
            search: 
            `?seats=${encodeURIComponent(JSON.stringify(seatCatSelected))}&eventId=${path.eventId}&eventName=${path.eventName}&showId=${showTimes.showtimeId}&showDate=${showdate}&venueId=${path.venue}&price=5000&seatType=2134`,
            })
            seatCatSelected = [];
        } else {
            alert('Please select seats')
        }
    }

    

    const seatNohandleChecked = async(e) => {
        let isChecked = e.target.checked;
        console.log(e.target.name)
        const targetName = Number(e.target.name)
        const seatcatdata = seatCatSelected.filter((seatCat) => seatCat.seatCategoryId === targetName)
        console.log(seatcatdata)


        if(isChecked){
            seatNoSelected.push(e.target.id)
          if(seatcatdata.length === 0){
            console.log('hhh')
            let seatNos = []
            seatNos.push(e.target.id)
            let seats = {
                    "seatCategoryId" : targetName,
                    "seatNumbers": seatNos,
                    "adultCount": seatNos.length,
                    "childCount": 0
                }
                seatCatSelected.push(seats)
            } else {
                let seatNos = seatcatdata[0].seatNumbers;
                console.log(seatNos)
                seatNos.push(e.target.id);
                seatcatdata[0].seatNumbers = seatNos
                seatcatdata[0].adultCount = seatNos.length
                //seatCatSelected.push(seatcatdata[0])
            }


        } else {
            seatNoSelected = seatNoSelected.filter((id) => e.target.id !== id);

          let seatNos = seatcatdata[0].seatNumbers;
          console.log(seatNos)
          let newSeatNos = seatNos.filter((id) => e.target.id !== id);
          seatcatdata[0].seatNumbers = newSeatNos
          seatcatdata[0].adultCount = newSeatNos.length
          //seatCatSelected.push(seatcatdata[0])

        }
        setChecked(!checked);
        
        console.log(seatCatSelected)
        console.log(seatNoSelected)
    }

    if(!dataLoaded){
        return(
            <>
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="danger" />
            </>
        )
    }
    else{
        // let rSeat = reservedSeats.reservedSeat
        
        // reservedSeats.reservedSeat.forEach(element => {
        //     element.seatRows.forEach(element =>{
        //         element.seats.forEach(element =>{
        //             console.log(element)
        //         })
        //     })
        // });
        
        return(
            <>
                <PrimarySearchAppBar />
                <div className="st_bt_top_header_wrapper float_left" style={{paddingTop:90}}>
                    <div className="container container_seat">
                        <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="st_bt_top_back_btn st_bt_top_back_btn_seatl float_left"> 
                            <Link to={`EventDetails/?movieid=${path.eventId}&name=${path.event}`} style={{ textDecoration: 'none' }}> &nbsp;Back</Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="st_bt_top_center_heading st_bt_top_center_heading_seat_book_page float_left">
                                <h3>{path.event}</h3>
                                {/* <h4>movieData.theater</h4>
                                <h4>movieData.movieDate , movieData.showTime </h4> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="st_bt_top_close_btn st_bt_top_close_btn2 float_left"> 
                            {/* <a href="#/movies" style={{color:"#fff"}}><i className="fa fa-times" /></a> */}
                            </div>
                            <div className="st_seatlay_btn float_left"> 
                                <Link style={{ textDecoration: 'none' }} onClick={proceedHandleClicked}
                                to="/eventbookingsummary"
                                // to={`/eventbookingsummary?seats=` + seatSelected + `&eventId=` + 
                                // path.eventId + `&eventName=` +
                                //  path.event + `&showId=` + showTimes.showtimeId + `&showDate=` +
                                //  showdate + `&venueId=` +path.venue }
                                 >
                                    Proceed
                                </Link>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div >
                    {/* <div className="row">
                    <div className="row myclass">
                    <div className="col d-flex justify-content-center">
                        <img src={stage} alt='stage' height='25%'/>
                    </div>
                    </div>
                        <div className='col-12'>
                            {reservedSeats.reservedSeat === undefined ?
                            <Spinner animation="grow" variant="danger" /> :
                            reservedSeats.reservedSeat.map((element) => (
                                element.seatRows.map((element) =>(                    
                                    element.seats.map((element) => (                                       
                                        <div className='checkbox-container'>
                                            {
                                                element.selected ?
                                                <input id={element.seatId} name={element.number} type='checkbox'  
                                                className='table-seats-booked' disabled={element.selected}/> :
                                                <input id={element.seatId} name={element.number} type='checkbox'  onChange={(e) =>handleChecked(e)} 
                                                className='table-seats' disabled={element.selected}/>
                                            }
                                        <label htmlFor={element.seatId} className='btn'>{element.number}</label>
                                        </div>
                                    ))
                                ))
                            ))
                            }
                        </div>
                        </div> */}
                        <Freddy seatNohandleChecked={seatNohandleChecked} seatArrangement={seatlistAll}/>
                </div>
            </>
    
        )
    }

    
}