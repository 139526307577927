import React, { Component } from "react";
import gplay from "../../images/content/f1.jpg";
import ios from "../../images/content/f2.jpg";

export class footer extends Component {
  render() {
    return (
      <div>
        <div className="prs_newsletter_wrapper">
          <div className="container">
            {/* <div class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <div class="prs_newsletter_text">
                          <h3>Get update sign up now !</h3>
                        </div>
                      </div>
                      <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <div class="prs_newsletter_field">
                          <input type="text" placeholder="Enter Your Email" />
                          <button type="submit">Submit</button>
                        </div>
                      </div>
                    </div> */}
          </div>
        </div>
        <div className="prs_footer_main_section_wrapper">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="prs_footer_cont1_wrapper prs_footer_cont1_wrapper_1">
                      <h2>LANGUAGE MOVIES</h2>
                      <ul>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">English movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Tamil movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Punjabi Movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Hindi movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Malyalam movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">English Action movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Hindi Action movie</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
              {/* <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="prs_footer_cont1_wrapper prs_footer_cont1_wrapper_2">
                      <h2>MOVIES by presenter</h2>
                      <ul>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Action movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Romantic movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Adult movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Comedy movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Drama movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Musical movie</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">Classical movie</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
              {/* <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="prs_footer_cont1_wrapper prs_footer_cont1_wrapper_3">
                      <h2>BOOKING ONLINE</h2>
                      <ul>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">www.example.com</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">www.hello.com</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">www.example.com</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">www.hello.com</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">www.example.com</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">www.hello.com</a>
                        </li>
                        <li><i className="fa fa-circle" /> &nbsp;&nbsp;<a href="#">www.example.com</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                {/* <div className="prs_footer_cont1_wrapper prs_footer_cont1_wrapper_4">
                      <h2>App available on</h2>
                      <p>Download App and Get Free Movie Ticket !</p>
                      <ul>
                        <li>
                          <a href="#">
                            <img src={gplay} alt="footer_img" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={ios} alt="footer_img" />
                          </a>
                        </li>
                      </ul>
                      <h5><span>Rs.500</span> Payback on App Download</h5>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="prs_bottom_footer_wrapper">
          <a href="javascript:" id="return-to-top">
            <i className="flaticon-play-button" />
          </a>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-8 col-xs-12">
                <div className="prs_bottom_footer_cont_wrapper">
{/*
                  <p>
                    Copyright 2022 <a href="#">TICKETSLK</a> . All rights
                    reserved - Design by <a href="#">~TLK~</a>
                  </p>
*/}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-4 col-xs-12">
                <div className="prs_footer_social_wrapper">
                  <ul>
                    <li>
                      <a href="https://web.facebook.com/TicketsLK/">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/ticketslk">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/tickets.lk/">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/ticketslk">
                        <i className="fa fa-youtube-play" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default footer;
