import React, { Component } from 'react'
import SideNavBar from '../../components/SideNavBar/SideNavBar';
import Preloader from '../../components/preloader/preloader';
import Footer from '../../components/footer/footer';

export class gallery extends Component {
    render() {
        return (
            <div>
            <SideNavBar />
            <div className="prs_title_main_sec_wrapper">
  <div className="prs_title_img_overlay" />
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_title_heading_wrapper">
          <h2>our gallery</h2>
          <ul>
            <li><a href="#">Home</a>
            </li>
            <li>&nbsp;&nbsp; &gt;&nbsp;&nbsp; Gallery</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="prs_gallery_main_section_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_heading_section_wrapper">
          <h2>Our Photo &amp; Videos</h2>
        </div>
      </div>
      <div className="portfolio-area ptb-100">
        <div className="container">
          <div className="portfolio-filter clearfix text-center">
            <ul className="list-inline" id="filter">
              <li><a className="active" data-group="all">All</a>
              </li>
              <li><a data-group="business">Photos</a>
              </li>
              <li><a data-group="website"> Videos</a>
              </li>
            </ul>
          </div>
          <div className="row three-column">
            <div id="gridWrapper" className="clearfix shuffle" style={{position: 'relative', height: 1374, transition: 'height 250ms ease-out 0s'}}>
              <div className="col-xs-12 col-sm-6 col-md-4 portfolio-wrapper III_column shuffle-item filtered" data-groups="[&quot;all&quot;, &quot;website&quot;, &quot;logo&quot;]" style={{position: 'absolute', top: 0, left: 0, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1)', opacity: 1, transition: 'transform 250ms ease-out 0s, opacity 250ms ease-out 0s'}}>
                <div className="portfolio-thumb">
                  <div className="gc_filter_cont_overlay_wrapper prs_ms_scene_slider_img">
                    <img src="images/content/gallery/g1.jpg" className="zoom image img-responsive" alt="service_img" />
                    <div className="prs_ms_scene_img_overlay"> <a href="images/content/gallery/g1.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="flaticon-tool" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 portfolio-wrapper III_column shuffle-item filtered" data-groups="[&quot;all&quot;, &quot;business&quot;, &quot;website&quot;, &quot;photoshop&quot;]" style={{position: 'absolute', top: 0, left: 0, transform: 'translate3d(390px, 0px, 0px) scale3d(1, 1, 1)', opacity: 1, transition: 'transform 250ms ease-out 0s, opacity 250ms ease-out 0s'}}>
                <div className="portfolio-thumb">
                  <div className="gc_filter_cont_overlay_wrapper prs_ms_scene_slider_img">
                    <img src="images/content/gallery/g2.jpg" className="zoom image img-responsive" alt="service_img" />
                    <div className="prs_ms_scene_img_overlay"> <a href="images/content/gallery/g2.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="flaticon-tool" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 portfolio-wrapper III_column shuffle-item filtered" data-groups="[&quot;all&quot;, &quot;logo&quot;, &quot;photoshop&quot;]" style={{position: 'absolute', top: 0, left: 0, transform: 'translate3d(780px, 0px, 0px) scale3d(1, 1, 1)', opacity: 1, transition: 'transform 250ms ease-out 0s, opacity 250ms ease-out 0s'}}>
                <div className="portfolio-thumb">
                  <div className="gc_filter_cont_overlay_wrapper prs_ms_scene_slider_img">
                    <img src="images/content/gallery/g3.jpg" className="zoom image img-responsive" alt="service_img" />
                    <div className="prs_ms_scene_img_overlay"> <a href="images/content/gallery/g3.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="flaticon-tool" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 portfolio-wrapper III_column shuffle-item filtered" data-groups="[&quot;all&quot;, &quot;logo&quot;, &quot;website&quot;, &quot;business&quot;]" style={{position: 'absolute', top: 0, left: 0, transform: 'translate3d(0px, 458px, 0px) scale3d(1, 1, 1)', opacity: 1, transition: 'transform 250ms ease-out 0s, opacity 250ms ease-out 0s'}}>
                <div className="portfolio-thumb">
                  <div className="gc_filter_cont_overlay_wrapper prs_ms_scene_slider_img">
                    <img src="images/content/gallery/g4.jpg" className="zoom image img-responsive" alt="service_img" />
                    <div className="prs_ms_scene_img_overlay"> <a href="images/content/gallery/g4.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="flaticon-tool" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 portfolio-wrapper III_column shuffle-item filtered" data-groups="[&quot;all&quot;, &quot;business&quot;, &quot;website&quot;, &quot;photoshop&quot;]" style={{position: 'absolute', top: 0, left: 0, transform: 'translate3d(390px, 458px, 0px) scale3d(1, 1, 1)', opacity: 1, transition: 'transform 250ms ease-out 0s, opacity 250ms ease-out 0s'}}>
                <div className="portfolio-thumb">
                  <div className="gc_filter_cont_overlay_wrapper prs_ms_scene_slider_img">
                    <img src="images/content/gallery/g5.jpg" className="zoom image img-responsive" alt="service_img" />
                    <div className="prs_ms_scene_img_overlay"> <a href="images/content/gallery/g5.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="flaticon-tool" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 portfolio-wrapper III_column shuffle-item filtered" data-groups="[&quot;all&quot;, &quot;business&quot;, &quot;website&quot;, &quot;logo&quot;]" style={{position: 'absolute', top: 0, left: 0, transform: 'translate3d(780px, 458px, 0px) scale3d(1, 1, 1)', opacity: 1, transition: 'transform 250ms ease-out 0s, opacity 250ms ease-out 0s'}}>
                <div className="portfolio-thumb">
                  <div className="gc_filter_cont_overlay_wrapper prs_ms_scene_slider_img">
                    <img src="images/content/gallery/g6.jpg" className="zoom image img-responsive" alt="service_img" />
                    <div className="prs_ms_scene_img_overlay"> <a href="images/content/gallery/g6.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="flaticon-tool" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 portfolio-wrapper III_column shuffle-item filtered" data-groups="[&quot;all&quot;, &quot;business&quot;, &quot;website&quot;, &quot;logo&quot;]" style={{position: 'absolute', top: 0, left: 0, transform: 'translate3d(0px, 916px, 0px) scale3d(1, 1, 1)', opacity: 1, transition: 'transform 250ms ease-out 0s, opacity 250ms ease-out 0s'}}>
                <div className="portfolio-thumb">
                  <div className="gc_filter_cont_overlay_wrapper prs_ms_scene_slider_img">
                    <img src="images/content/gallery/g7.jpg" className="zoom image img-responsive" alt="service_img" />
                    <div className="prs_ms_scene_img_overlay"> <a href="images/content/gallery/g7.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="flaticon-tool" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 portfolio-wrapper III_column shuffle-item filtered" data-groups="[&quot;all&quot;, &quot;business&quot;, &quot;website&quot;, &quot;logo&quot;]" style={{position: 'absolute', top: 0, left: 0, transform: 'translate3d(390px, 916px, 0px) scale3d(1, 1, 1)', opacity: 1, transition: 'transform 250ms ease-out 0s, opacity 250ms ease-out 0s'}}>
                <div className="portfolio-thumb">
                  <div className="gc_filter_cont_overlay_wrapper prs_ms_scene_slider_img">
                    <img src="images/content/gallery/g8.jpg" className="zoom image img-responsive" alt="service_img" />
                    <div className="prs_ms_scene_img_overlay"> <a href="images/content/gallery/g8.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="flaticon-tool" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 portfolio-wrapper III_column shuffle-item filtered" data-groups="[&quot;all&quot;, &quot;business&quot;, &quot;website&quot;, &quot;logo&quot;]" style={{position: 'absolute', top: 0, left: 0, transform: 'translate3d(780px, 916px, 0px) scale3d(1, 1, 1)', opacity: 1, transition: 'transform 250ms ease-out 0s, opacity 250ms ease-out 0s'}}>
                <div className="portfolio-thumb">
                  <div className="gc_filter_cont_overlay_wrapper prs_ms_scene_slider_img">
                    <img src="images/content/gallery/g9.jpg" className="zoom image img-responsive" alt="service_img" />
                    <div className="prs_ms_scene_img_overlay"> <a href="images/content/gallery/g9.jpg" className="venobox info vbox-item" data-title="PORTFOLIO TITTLE" data-gall="gall12"><i className="flaticon-tool" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="prs_animate_btn1 prs_upcom_main_wrapper prs_gallery_btn_wrapper">
                <ul>
                  <li><a href="#" className="button button--tamaya prs_upcom_main_btn" data-text="view all"><span>View All</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="prs_patner_main_section_wrapper">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_heading_section_wrapper">
          <h2>Our Patner’s</h2>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="prs_pn_slider_wraper">
          <div className="owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer"><div className="owl-stage" style={{transform: 'translate3d(-1546px, 0px, 0px)', transition: 'all 1.2s ease 0s', width: 3480}}><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p1.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p2.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p3.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p4.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p5.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p6.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p1.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p2.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p3.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p4.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p5.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p6.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p1.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned active" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p2.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p3.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p4.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p5.jpg" alt="patner_img" />
                    </div>
                  </div></div><div className="owl-item cloned" style={{width: '173.333px', marginRight: 20}}><div className="item">
                    <div className="prs_pn_img_wrapper">
                      <img src="images/content/p6.jpg" alt="patner_img" />
                    </div>
                  </div></div></div></div><div className="owl-nav disabled"><div className="owl-prev"><i className="flaticon-play-button" /></div><div className="owl-next"><i className="flaticon-play-button" /></div></div><div className="owl-dots disabled"><div className="owl-dot active"><span /></div></div></div>
        </div>
      </div>
    </div>
  </div>
</div>

            <Footer />
            </div>
        )
    }
}

export default gallery
