import React, { Component } from "react";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import PrimarySearchAppBar from "../../components/navBar/navBar";
import Preloader from "../../components/preloader/preloader";
import Footer from "../../components/footer/footer";
import ScreeningMovies from "../movies/screeningMovies";
import TopEventSlider from "../event/topEventsSlider";
import Partners from "../partners/partners";
import MainBanner from "../movies/bannerMovieCarousel";
import Container from "@material-ui/core/Container";

import { Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { CardDeck } from "react-bootstrap";

import notimeto from "../../images/header/ntttd.jpg";
import hotel from "../../images/header/htel.jpg";
import eternal from "../../images/header/Eternals_3.jpg";
import black from "../../images/header/black.jpg";
import suic from "../../images/header/suic.jpg";
import ghost from "../../images/header/ghost.jpg";
import VenomC from "../../images/header/VenomC.jpg";
import featEvent from "../../images/header/featEvent.jpg";

export class movies extends Component {
  render() {
    return (
      <div>
        <PrimarySearchAppBar />
        <div className="prs_mc_slider_main_wrapper">
          <MainBanner />
        </div>
        <div className="prs_mc_category_sidebar_main_wrapper">
          <div className="container">
            <div className="row">
{/*
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 hidden-sm hidden-xs">
                <div className="prs_mcc_left_side_wrapper">
                  <div className="prs_mcc_left_searchbar_wrapper">
                    <input type="text" placeholder="Search Movie" disabled />
                    <button>
                      <i className="flaticon-tool" />
                    </button>
                  </div>
                  <div className="prs_mcc_bro_title_wrapper">
                    <h2>browse title</h2>
                    <ul></ul>
                  </div>
                  <div className="prs_mcc_event_title_wrapper">
                    <h2>Top Events</h2>
                  </div>
                </div>
              </div>
*/}
              <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <div className="prs_mcc_right_side_wrapper">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="prs_mcc_right_side_heading_wrapper">
                        <h2>Book Now</h2>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <Container>
                        <ScreeningMovies />
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="prs_theater_main_slider_wrapper">
          <div className="prs_theater_img_overlay" />
          <div className="prs_theater_sec_heading_wrapper">
            <h2>TOP EVENTS HAPPENING</h2>
          </div>
          <div className="wrap-album-slider">
            <TopEventSlider />
          </div>
        </div> */}
        <div
          className="prs_patner_main_section_wrapper prs_patner_main_section_wrapper_ind3"
          style={{ paddingTop: 1 }}
        >
          <div className="container"></div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default movies;
