import React , { Component, ReactNode, useEffect }from 'react';
import { Carousel } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { render } from '@testing-library/react';
import { Public } from '@material-ui/icons';
import Spinner from 'react-bootstrap/Spinner'

const MAINMOVIEBANNERHOME = [
    {
      id: 1,
      genres: "ACTION / ADVENTURE",
      language: "ENGLISH, HINDI",
      movieTitle: "Avengers End Game",
      movieType: "2D & 3D",
      dateScreen: "14 Aug 2021",
      duration: "240mins",
      ratings: 50195,
      bannerImage: "https://2cadxu4apic638boqt1kzcoz-wpengine.netdna-ssl.com/wp-content/uploads/2019/08/69412000_10156402547922805_3521455265222230016_o.jpg"
    },
    {
      id: 2,
      genres: "ACTION / ADVENTURE",
      language: "ENGLISH, HINDI, TAMIL",
      movieTitle: "No Time To Die",
      movieType: "IMAX 3D",
      dateScreen: "14 Jul 2021",
      duration: "140mins",
      ratings: 30195,
      bannerImage: "https://images3.alphacoders.com/106/thumb-1920-1064725.jpg"
    },
    {
      id: 3,
      genres: "ANIMATION / FANTASY",
      language: "ENGLISH, HINDI, TAMIL",
      movieTitle: "Hotel Transylvania 4",
      movieType: "2D & 3D",
      dateScreen: "14 Oct 2021",
      duration: "160mins",
      ratings: 10195,
      bannerImage: "https://wallpaperaccess.com/full/1593220.jpg"
    },
    {
      id: 4,
      genres: "ACTION / ADVENTURE",
      language: "ENGLISH",
      movieTitle: "Eternals",
      movieType: "2D & 3D",
      dateScreen: "14 Sep 2021",
      duration: "160mins",
      ratings: 10195,
      bannerImage: "https://fandomwire.com/wp-content/uploads/2020/08/4-13.jpg"
    }
  ]

  const state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 2,
        },
    },
}

export default function MainBanner (){

  const [movieBanners, setMovieBanners] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false)

  useEffect(() => {
    const url = `${process.env.REACT_APP_DEV_BASE_URL}/v1/image/banners?eventTypeId=0`;
    const requestOptions = (token) => {
      return ({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
      })
    };
    const fetchData = async () => {
      try {
        const response = await fetch(url, requestOptions());
        const json =  await response.json();
        // console.log(json);
        // console.log(json.data.venueDateShowtime)
        await setMovieBanners(json.data.bannerEvents);
        setDataLoaded(true)
        console.log(movieBanners)
      }
      catch (error) {
        console.log("error",error);
      }
    };
    fetchData();
  },[])

  if (!dataLoaded) {
    return (
    <div>
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
      <Spinner animation="grow" variant="danger" />
    </div>
    );
  } else {
    return(
      <div>
          <OwlCarousel className='owl-theme' style={{boxShadow: "5px 5px 17px 10px rgba(0,0,0,0.45)"}}
          loop center margin={5} autoplay ={true} dots={false} items={2} touchDrag={true} lazyLoad={true} 
          responsiveClass={true} responsive={state.responsive} animateOut={'fadeOut'} animateIn={'flipInX'}>
          {movieBanners.map((movieBannerp) => {
              return (
                  <div class="item" >
                      {/* <h4>{movieBannerp.movieTitle}</h4> */}
                      <img src={movieBannerp.bannerImage} alt={movieBannerp.movieTitle}/>
                  </div>  
              );
        })}
          </OwlCarousel>
      </div>
  )
  }
}
