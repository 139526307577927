import React from 'react'
import "../../css/style.css";
import "../../css/seat.css";
import TnC from './termOfUse';
import FAQ from './FAQ';
import CardData from './cardData';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MovieFilterIcon from '@material-ui/icons/MovieFilter';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import GavelIcon from '@material-ui/icons/Gavel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Spinner from 'react-bootstrap/Spinner'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import queryString from 'query-string';

import poster from '../../images/header/placeholder.png';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function TabPanel(props) {
    const {children, value, index, ...other} =props;

    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-force-tabpanel-${index}`}
          aria-labelledby={`scrollable-force-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }
  
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingTop: 25,
    },
    bookedTickets_item_backGround: {
        backgroundColor: '#fff',
        boxShadow: "2px 8px 10px 1px rgba(0,0,0,0.45)",
        borderRadius: 10,
        padding: 10,
    },
  }));



export default function UserBookings ({dataParentToChild, dataLoaded}){

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [open, setOpen ] =React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
      };
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

console.log(dataParentToChild)

    return(
        <div>
            <div className={classes.root}>
                <AppBar position="static" color="#fff">
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="scrollable force tabs example"
                    >
                    <Tab label="Account" icon={<AccountBalanceWalletIcon />} {...a11yProps(0)} />
                    <Tab label="Bookings" icon={<MovieFilterIcon />} {...a11yProps(1)} />
                    <Tab label="FAQ" icon={<LiveHelpIcon />} {...a11yProps(2)} />
                    <Tab label="T & C" icon={<GavelIcon />} {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={1} style={{backgroundColor: '#dedede'}}>
                    <div className="row scroll_to_booked_tickets">
                        {dataParentToChild.map((bookingsData)=>{
                            return(
                        <div>     
                        <div className={classes.bookedTickets_item_backGround}> 
                            <div className="row">
                                <div className="col-xl-2 col-lg-2 col-md-1 col-sm-2 col-xs-1 col-3">
                                    <img src={poster} width="110px" height="auto" style={{border: '1px solid #1c1b16'}}/>
                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"></div>
                                <div className="col-xl-5 col-lg-5 col-md-7 col-sm-7 col-xs-9 col-6" style={{paddingLeft:25}}>                               
                                    <h5><b>{bookingsData.movieName}</b></h5>
                                    <h5>{bookingsData.theaterName}</h5>
                                    <h5><small>{bookingsData.showTime}</small></h5>
                                    {bookingsData.bookedSeats.map((seatNos) => {
                                       return <h5><small>{seatNos.seats}</small></h5>
                                    })}                               
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-2 col-sm-2 col-xs-1 col-12" style={{float: 'right'}}>
                                    <h6 style={{float: 'right', color: '#085ca6'}}><b>{bookingsData.refcode}</b></h6>
                                    <br/>
                                    <Button variant="contained" color="secondary" style={{float: 'right'}}
                                    onClick={handleClickOpen}>
                                        More Details
                                    </Button>
                                    <Dialog
                                        open={open}
                                        TransitionComponent={Transition}
                                        fullScreen={fullScreen}
                                        keepMounted
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                        >
                                        <DialogTitle id="alert-dialog-slide-title">{bookingsData.movieName}</DialogTitle>
                                        <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            Let Google help apps determine location. This means sending anonymous location data to
                                            Google, even when no apps are running.
                                        </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                        <Button onClick={handleClose} color="primary">
                                            Disagree
                                        </Button>
                                        <Button onClick={handleClose} color="primary">
                                            Agree
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            </div>
                        </div>
                        <br/>
                        </div>
                            )
                        })}                        
                    </div>
                    <br/>
                </TabPanel>
                <TabPanel value={value} index={0} >
                    <CardData />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <FAQ/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <TnC/>
                </TabPanel>
            </div>
        </div>
    );
    
}