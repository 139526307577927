import React, { Component } from "react";
import "../../css/style.css";
import "../../css/seat.css";
import { Link } from "react-router-dom";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import PrimarySearchAppBar from "../../components/navBar/navBar";
import Footer from "../../components/footer/footer";

import playIcon from "../../images/index_III/icon.png";

//Ads Images
import addSpace from "../../images/header/add.jpg";

import TrendingNews from "../singleMovieDetails/trandingNews";
import MovieCast from "../singleMovieDetails/cast";
import TopMovieBanners from "../singleMovieDetails/topMovies";
import Button from "@material-ui/core/Button";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import LocalActivityTwoToneIcon from "@material-ui/icons/LocalActivityTwoTone";
import queryString from "query-string";

import Preloader from "../../components/preloader/preloader";
import { getYear } from "date-fns";

var movieName = [];

export class MovieDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movieData: {},
      movieInformation: {},
      movieDataLoaded: false,
      omdbInfo: {},
      cast: [],
      writers: [],
    };
  }

  componentDidMount() {
    let movieInfo = queryString.parse(window.location.search);
    this.setState({
      movieData: movieInfo,
    });
    this.getMovieInfo(movieInfo);
    this.getOmdbInfo(movieInfo);
  }

  getMovieInfo = async (movieInfo) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          client_token: "ebd86470-7e90-4ece-9e89-1b6d4d2cbb61",
        },
      };
      console.log(movieInfo);
      await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/` + movieInfo.movieid,
        requestOptions
      )
        .then((response) => response.json())
        .then((movieDataInfo) => {
          this.setState({
            movieInformation: movieDataInfo.data,
            movieDataLoaded: true,
          });
        })
        .catch(console.log);
    } catch (err) {
      console.log("error", err);
    }
  };

  getOmdbInfo = async (movieName) => {
    let cYear = new Date();
    console.log(cYear.getFullYear())
    try {
      await fetch(
        `${process.env.REACT_APP_OMDB_API}/?t=${movieName.name.replace('- 3D','')}&apikey=${process.env.REACT_APP_OMDB_KEY}`
      )
        .then((response) => response.json())
        .then((info) => {
          this.setState({
            omdbInfo: info,
          });
          console.log(info);
          let cast = (info.Actors.split(","));
          let writer = (info.Writer.split(","));
          let wrt = []
          let cs = []
          for(let i=0;i<cast.length;i++){
            cs.push(cast[i].trim());
          }
          for(let i=0;i<writer.length;i++){
            wrt.push(writer[i].trim());
          }
          this.setState({
            cast: cs,
            writers: wrt
          })
        })
        .catch(console.log);
    } catch (err) {
      console.log("Error : ", err);
    }
  };

  render() {
    if (!this.state.movieDataLoaded) {
      return <Preloader />;
    } else {
      return (
        <div>
          {/* <SideNavBar /> */}
          <PrimarySearchAppBar />

          {/* Moive main banner start*/}

          <div className="prs_top_video_section_wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="st_video_slider_inner_wrapper_details float_left">
                    <div className="st_video_slider_overlay_Movie"></div>
                    <div className="st_video_slide_sec float_left">
                      <a
                        rel="external"
                        href=""
                        title="title"
                        className="test-popup-link"
                      >
                        <img src={playIcon} alt="img" />
                      </a>
                      {/* <h3>{movieName.name}</h3> */}
                      <h3>{this.state.movieInformation.name}</h3>
                      <p>{this.state.movieInformation.tagLine}</p>
                      <h4>{this.state.omdbInfo.Genre}</h4>
                      <h4><i class="fa fa-clock"></i> {this.state.omdbInfo.Runtime}</h4>
                      {/* <h5><span>2d</span> <span>3d</span> <span>D 4DX</span> <span>Imax 3D</span></h5> <br/> */}
                      <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<LocalActivityTwoToneIcon />}
                      >
                        <Link
                          to={
                            "/movieBooking?movieid=" +
                            this.state.movieInformation.id +
                            "&name=" +
                            this.state.movieInformation.name
                          }
                          style={{ color: "#fff", textDecoration: "none" }}
                        >
                          BOOK NOW
                        </Link>
                      </Button>
                    </div>
                    <div className="st_video_slide_social float_left">
                      <div className="st_slider_rating_btn_heart st_slider_rating_btn_heart_5th">
                        <h5>
                        <i class="fab fa-imdb"></i> {this.state.omdbInfo.imdbRating}
                        </h5>
                        <h4>{this.state.omdbInfo.Rated}</h4>
                      </div>
                      <div className="st_video_slide_social_left float_left">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-youtube"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="st_video_slide_social_right float_left">
                        <ul>
                          {/* <li data-animation="animated fadeInUp" class=""><i class="far fa-calendar-alt"></i> 14 Dec, 2018</li>
                    <li data-animation="animated fadeInUp" class=""><i class="far fa-clock"></i> 2 hrs 23 mins</li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Moive main banner ends*/}

          {/* rating starts */}
          <div className="st_slider_rating_main_wrapper float_left"></div>
          {/* rating ends */}

          {/* trending starts */}
          <div className="st_slider_index_sidebar_main_wrapper st_slider_index_sidebar_main_wrapper_md float_left">
            <div className="container">
              <div className="row">
                <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                  <div className="st_indx_slider_main_container float_left">
                    <div className="row">
                      {/* <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="ne_busness_main_slider_wrapper float_left">
                              <div className="ne_recent_heading_main_wrapper ne_recent_heading_main_wrapper_index_II float_left">
                                <h2>TRENDING STORIES</h2>
                              </div>
                            </div>
                          </div>
                          <TrendingNews />
                        </div>
                      </div> */}

                      {/* trending ends */}

                      {/* summary starts */}

                      <div className="col-md-12">
                        <div className="prs_upcome_tabs_wrapper prs_upcome_tabs_wrapper_mss float_left">
                          <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className="active">
                              <a
                                href="#home"
                                aria-controls="best"
                                role="tab"
                                data-toggle="tab"
                              >
                                Summary
                              </a>
                            </li>
                            <li role="presentation">
                              <a
                                href="#menu2"
                                aria-controls="trand"
                                role="tab"
                                data-toggle="tab"
                              >
                                Behind The Scenes
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="tab-content">
                          <div id="home" className="tab-pane active">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="st_md_summ_pera float_left">
                                  <h5>SYNOPSIS</h5>
                                  <p>{this.state.movieInformation.summary}</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="st_md_summ_client_slider float_left">
                                  <p>CAST</p>
                                  <MovieCast cast={this.state.cast}/>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="st_md_summ_client_slider float_left">
                                  <p>Crew</p>
                                  <MovieCast cast={this.state.writers} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="menu2" className="tab-pane fade">
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
                                  <img
                                    src="images/content/movie_single/sc1.jpg"
                                    alt="scene_img"
                                  />
                                  <div className="prs_ms_scene_img_overlay">
                                    {" "}
                                    <a
                                      href="images/content/movie_single/sc1.jpg"
                                      class="venobox info"
                                      data-title="PORTFOLIO TITTLE"
                                      data-gall="gall12"
                                    >
                                      <i class="flaticon-tool"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
                                  <img
                                    src="images/content/movie_single/sc2.jpg"
                                    alt="scene_img"
                                  />
                                  <div className="prs_ms_scene_img_overlay">
                                    {" "}
                                    <a
                                      href="images/content/movie_single/sc2.jpg"
                                      class="venobox info"
                                      data-title="PORTFOLIO TITTLE"
                                      data-gall="gall12"
                                    >
                                      <i class="flaticon-tool"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
                                  <img
                                    src="images/content/movie_single/sc3.jpg"
                                    alt="scene_img"
                                  />
                                  <div className="prs_ms_scene_img_overlay">
                                    {" "}
                                    <a
                                      href="images/content/movie_single/sc3.jpg"
                                      class="venobox info"
                                      data-title="PORTFOLIO TITTLE"
                                      data-gall="gall12"
                                    >
                                      <i class="flaticon-tool"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
                                  <img
                                    src="images/content/movie_single/sc4.jpg"
                                    alt="scene_img"
                                  />
                                  <div className="prs_ms_scene_img_overlay">
                                    {" "}
                                    <a
                                      href="images/content/movie_single/sc4.jpg"
                                      class="venobox info"
                                      data-title="PORTFOLIO TITTLE"
                                      data-gall="gall12"
                                    >
                                      <i class="flaticon-tool"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
                                  <img
                                    src="images/content/movie_single/sc5.jpg"
                                    alt="scene_img"
                                  />
                                  <div className="prs_ms_scene_img_overlay">
                                    {" "}
                                    <a
                                      href="images/content/movie_single/sc5.jpg"
                                      class="venobox info"
                                      data-title="PORTFOLIO TITTLE"
                                      data-gall="gall12"
                                    >
                                      <i class="flaticon-tool"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <div className="prs_ms_scene_slider_img prs_ms_scene_slider_img22">
                                  <img
                                    src="images/content/movie_single/sc6.jpg"
                                    alt="scene_img"
                                  />
                                  <div className="prs_ms_scene_img_overlay">
                                    {" "}
                                    <a
                                      href="images/content/movie_single/sc6.jpg"
                                      class="venobox info"
                                      data-title="PORTFOLIO TITTLE"
                                      data-gall="gall12"
                                    >
                                      <i class="flaticon-tool"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                  <div className="st_ind_sidebar_right_wrapper float_left">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="st_ind_sidebar_md_textbox float_left">
                          <p>
                            Highest grossing movie in Colombo in last 24 hours
                          </p>{" "}
                          <a href="#">Know Box Office Report</a>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="st_ind_sidebar_md_textbox_btn float_left">
                          <ul>
                            <li>
                              {" "}
                              <a href="#">
                                Corporate booking &nbsp;{" "}
                                <i className="fas fa-long-arrow-alt-right"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="st_ind_sidebar_advertiz st_ind_sidebar_advertiz_md float_left">
                          <a href="#">
                            <img src={addSpace} alt="img" />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="st_ind_sidebar_advertiz_social st_video_slide_social_left  float_left">
                          <h3>Profiles</h3>
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fa fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-linkedin"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* top movie slider starts */}

          <div className="prs_theater_main_slider_wrapper">
            <div className="prs_theater_img_overlay"></div>
            <div className="prs_theater_sec_heading_wrapper">
              <h2>TOP MOVIES IN THEATRES</h2>
            </div>

            <div className="wrap-album-slider">
              <TopMovieBanners />
            </div>
          </div>

          <Footer />
        </div>
      );
    }
  }
}

export default MovieDetails;
