import React, { Component } from "react";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import Preloader from "../../components/preloader/preloader";
import Footer from "../../components/footer/footer";
// import { Pagination } from "antd";
// import Pagination from '@material-ui/core/Pagination';
import { Link } from "react-router-dom";
import { List } from "antd";
import { Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { CardDeck } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
// import { Pagination } from 'react-bootstrap'
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ButtonMui from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import notFound from "../../images/header/404.png";
import Pagination from "@mui/material/Pagination";

export class ScreeningMovies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minValue: 0,
      maxValue: 9,
    };
    this.state = {
      MovieList: [],
      dataLoaded: false,
      movieCount: 0,
      movieType: "1",
      filterdMovie: [],
      upcoming: [],
      nowShowing: [],
    };
    this.movieTypeSelect = this.movieTypeSelect.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        client_token: "ebd86470-7e90-4ece-9e89-1b6d4d2cbb61",
      },
    };
    fetch(
      `${process.env.REACT_APP_DEV_BASE_URL}/v1/movie/online?countryId=1&eventType=0`,
      requestOptions
    )
      .then((response) => response.json())
      .then((MovieList) => {
        // console.log(MovieList.data.movies);
        let upcome = [];
        let nowshow = [];
        MovieList.data.movies.forEach((element) => {
          if (element.upcoming) {
            upcome.push(element);
          } else {
            nowshow.push(element);
          }
        });
        this.setState({
          MovieList: MovieList.data.movies,
          filterdMovie: MovieList.data.movies,
          dataLoaded: true,
          movieCount:
            MovieList.data.movies.length < 6 ? 1 : MovieList.data.movies.length,
          nowShowing: nowshow,
          upcoming: upcome,
        });
      })
      .catch(console.log);
  }

  handleChange = (value) => {
    if (value <= 1) {
      this.setState({
        minValue: 0,
        maxValue: 9,
      });
    } else {
      this.setState({
        minValue: this.state.maxValue,
        maxValue: value * 9,
      });
    }
  };

  movieTypeSelect = (e) => {
    const value = e.target.value;
    if (value === "1") {
      this.setState({
        filterdMovie: this.state.MovieList,
      });
    } else if (value === "2") {
      this.setState({
        filterdMovie: this.state.nowShowing,
      });
    } else if (value === "3") {
      this.setState({
        filterdMovie: this.state.upcoming,
      });
    }
    this.setState({
      movieType: value,
    });
  };
  render() {
    const { MovieList, dataLoaded } = this.state;
    // console.log(MovieList)
    if (!dataLoaded) {
      return (
        <div>
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="danger" />
        </div>
      );
    } else {
      return (
        <div>
{/*
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={this.state.movieType}
              onChange={this.movieTypeSelect}
            >
              <FormControlLabel
                value="1"
                control={<Radio color="error" />}
                label="All"
              />
              <FormControlLabel
                value="2"
                control={<Radio color="error" />}
                label="Now Showing"
              />
              <FormControlLabel
                value="3"
                control={<Radio color="error" />}
                label="Upcoming"
              />
            </RadioGroup>
          </FormControl>
*/}
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {this.state.filterdMovie.length > 0 ? (
              <Row>
                {" "}
                <br />
                {this.state.filterdMovie &&
                  this.state.filterdMovie.length > 0 &&
                  this.state.filterdMovie
                    .slice(this.state.minValue, this.state.maxValue)
                    .map((MovieList) => (
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        {" "}
                        <br />
                        <Card className="movieCard">
                          <Card.Img
                            variant="top"
                            src={MovieList.thumbnail}
                            height="300"
                            max-width="100% !important"
                          />
                          <Card.Body>
                            <Card.Title as="h6">
                              <b>{MovieList.name}</b>
                            </Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                              {MovieList.genre}
                            </Card.Subtitle>
                            {/* <Link
                              to={
                                "/MovieDetail?movieId=" +
                                MovieList.id +
                                "&name=" +
                                MovieList.name
                              }
                            >
                              <small className="text-muted">More Details</small>
                            </Link> */}
                            {/* <Link to={{ pathname: searchString, search: qs.stringify(newQueryParam)}} >Proceed to Pay</Link> */}
                          </Card.Body>
                          <Card.Footer className="text-center">
                            {MovieList.bookingOpen ? (
                              <Button
                                variant="danger"
                                style={{ backgroundColor: "#ff4444" }}
                              >
                                <Link
                                  to={
                                    "/movieBooking?movieid=" +
                                    MovieList.id +
                                    "&name=" +
                                    MovieList.name
                                  }
                                  style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                  }}
                                >
                                  Book Now
                                </Link>
                              </Button>
                            ) : (
                              <Button
                                variant="danger"
                                style={{ backgroundColor: "#2488ed", }}
                              >
                                <Link
                                  to={
                                    "/movieBooking?movieid=" +
                                    MovieList.id +
                                    "&name=" +
                                    MovieList.name
                                  }
                                  style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                    pointerEvents: 'none',
                                  }}
                                >
                                  Coming Soon
                                </Link>
                              </Button>
                            )}
                          </Card.Footer>
                        </Card>
                      </div>
                    ))}
              </Row>
            ) : (
              <>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={notFound} alt="" width="50%" />
                  <h4>No Movies Found</h4>
                </Stack>
              </>
            )}
            {/* <br /> */}
            {/* <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <Pagination
                  // defaultCurrent={1}
                  // defaultPageSize={6} //default size of page
                  // onChange={this.handleChange}
                  // total={15} //total number of card data available
                  count={this.state.movieCount}
                  color="primary"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
            </div> */}
          </div>
        </div>
      );
    }
  }
}

export default ScreeningMovies;
