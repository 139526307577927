import React, { Component } from 'react'
import preload from '../../images/header/horoscope.gif';

export class preloader extends Component {
    render() {
        return (
            <div id="preloader">
            <div id="status">
              <img src={preload} alt="loader" />
            </div>
          </div>
        )
    }
}

export default preloader
