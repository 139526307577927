import React, {Component, useState} from 'react'
import "../../css/style.css";
import "../../css/seat.css";
import SeatPicker from 'react-seat-picker';
// const SeatPicker = require('react-seat-picker');
import Screen from '../../images/content/screen.png';
import queryString from 'query-string';
import LibertyLite from './libertyLite';

var seats = [] 
var MovieSeatList = []
var data 
var seatPLan = []
var MTSdata = []
var seatsSelected = []
const seatList = [
  { id: 0,
    row:"A",
   seats:[
    {id: 0, seatID: "c1",name:"A1" ,booked:"seat_disable",section: "st_eco_second_row"},
    {id: 1, seatID: "c2",name:"A2" ,booked:"",section: ""},
    {id: 2, seatID: "c3",name:"A3" , booked:"seat_disable",section: ""},
    {id: 3, seatID: "c4",name:"A4" , booked:"",section: ""},
    {id: 4, seatID: "c5",name:"A5" , booked:"seat_disable",section: ""},
    {id: 5, seatID: "c6",name:"A6" , booked:"",section: ""},
  ]   
},
{id: 1,
  row:"B",
seats:[{id: 0, seatID: "c7",name:"B1" ,booked:"",section: "st_eco_second_row"},
{id: 1, seatID: "c8",name:"B2" ,booked:"seat_disable",section: "st_eco_second_row"},
{id: 2, seatID: "c8",name:"B3" , booked:"",section: "st_eco_second_row"},
{id: 3, seatID: "c9",name:"B4" , booked:"seat_disable",section: "st_eco_second_row"},
{id: 4, seatID: "c10",name:"B5" , booked:"",section: "st_eco_second_row"},
{id: 5, seatID: "c11",name:"B6" , booked:"seat_disable",section: "st_eco_second_row"},
]   
},
]
export default class SeatPlan extends Component {

constructor(props){
  super(props);
  this.state={
    MovieSeatList: this.props.dataParentToChild,
    MTSdata: this.props.movieInfomation,
  }
  console.log(MovieSeatList)
}

  state = {
    loading: false,
    selectedSeats: [],
    isChecked: false,
  };

  assignMyState = (data = []) => {
    this.setState({ selectedSeats: data });
    console.log(this.state);
  };
 
  addSeatCallback = ({ row, number, id }, addCb) => {
    this.setState({
      loading: true
    }, async () => {
      await new Promise(resolve => setTimeout(resolve, 1500))
      console.log(`Added seat ${number}, row ${row}, id ${id}`)
      const newTooltip = `tooltip for id-${id} added by callback`
      addCb(row, number, id, newTooltip)
      this.setState({ loading: false })
    })
  }
 
  addSeatCallbackContinousCase = ({ row, number, id }, addCb, params, removeCb) => {
    this.setState({
      loading: true
    }, async () => {
      if (removeCb) {
        await new Promise(resolve => setTimeout(resolve, 750))
        console.log(`Removed seat ${params.number}, row ${params.row}, id ${params.id}`)
        removeCb(params.row, params.number)
      }
      await new Promise(resolve => setTimeout(resolve, 750))
      console.log(`Added seat ${number}, row ${row}, id ${id}`)
      const newTooltip = `tooltip for id-${id} added by callback`
      addCb(row, number, id, newTooltip)
      // seats = {number}
      seats.push(number)
      this.setState({ loading: false })
      // console.log(seats)
      this.props.onData(seats)
    })
  }

 
  removeSeatCallback = ({ row, number, id }, removeCb) => {
    this.setState({
      loading: true
    }, async () => {
      await new Promise(resolve => setTimeout(resolve, 1500))
      console.log(`Removed seat ${number}, row ${row}, id ${id}`)
      // A value of null will reset the tooltip to the original while '' will hide the tooltip
      const newTooltip = ['A', 'B', 'C'].includes(row) ? null : ''
      removeCb(row, number, newTooltip)
      this.setState({ loading: false })
      seats.splice(seats.indexOf(number), 1);
      this.props.onData(seats)
    })
  }

  handleChanged(e){
    let isChecked = e.target.checked;
    seats.push(e.target.name)
    if(isChecked){
        alert(seats);
    }else{
        seats = seats.filter((name) => e.target.name !== name);
        alert("removed",e.target.name)
    }
    console.log(seats)
    this.props.onData(seats)
  }


  


  
  render() {

    const {MovieSeatList, MTSdata, selectedSeatArray, data} = this.state;
    console.log(MovieSeatList)
    console.log('selected Seats',data)


    seatPLan = MovieSeatList
    console.log(seatPLan)
    console.log(MTSdata)


    const {loading} = this.state
    if ((MTSdata.theater) === "MaxX Lite Moratuwa") {
      return(
        // <LibertyLite seatData={MovieSeatList} handleMyState={this.assignMyState}/>
        <div className="st_seatlayout_main_wrapper float_left">
        <div className="container container_seat">
            <div className="st_seat_lay_heading float_left">
            {/* <h3>Liberty Lite</h3> */}
            </div>
            <div className="st_seat_full_container">
            <div className="st_seat_lay_economy_wrapper float_left">
                <div className="screen">
                <img src={Screen} alt="Screen"/>
                </div>
                <div className="st_seat_lay_economy_heading float_left">
                <h3>ODC</h3>
                </div>
                <div className="st_seat_lay_row float_left">
 <ul>
      {seatList.map((rowsData, index) => (
        <>
        <div className="st_seat_lay_row float_left">
          <li className="st_seat_heading_row" key={index}>
            {rowsData.row}
          </li>
          {rowsData.hasOwnProperty("seats") && (
            <ul>
              {rowsData.seats.map((seat, idx) => (
                <ul className={seat.section}>
                <li className={seat.booked} key={idx}>
                  <input
                    type="checkbox"
                    id={seat.seatID}
                    name={seat.name}
                    onChange={(e) => this.handleChanged(e)}
                  />
                  <label htmlFor={seat.seatID} />
                </li>
                </ul>
              ))}
            </ul>
          )}
          </div>
        </>
      ))}
    </ul>
                </div></div></div></div></div>
      )
    } else {
      return (
        <div>
          {/* <h1>Seat Picker</h1> */}
          {/* <div>
            <SeatPicker   className="AppSeat"
              addSeatCallback={this.addSeatCallback}
              removeSeatCallback={this.removeSeatCallback}
              rows={rows}
              maxReservableSeats={10}
              alpha
              visible
              selectedByDefault
              loading={loading}
              tooltipProps={{multiline: true}}
            />
          </div>
          <h1>Seat Picker Continuous Case</h1> */}
  
  
        {/* <div className="col-lg-4 col-md-3 col-sm-3 col-xs-3">{data}</div>
        <div className="col-lg-4 col-md-6 col-sm-3 col-xs-3" style={{ marginTop: '10px', alignItems:'center' }}>
        <img src={Screen} width="450px" alt='screen'/>
        {seatPLan.map((seatingPlan) => {
            return(
              <SeatPicker
              addSeatCallback={this.addSeatCallbackContinousCase}
              removeSeatCallback={this.removeSeatCallback}
              rows={seatingPlan.seatRows}
              maxReservableSeats={5}
              // alpha
              // visible
              selectedByDefault
              loading={loading}
              tooltipProps={{ multiline: true }}
              continuous
            />
            );
          })}
            <SeatPicker
              addSeatCallback={this.addSeatCallbackContinousCase}
              removeSeatCallback={this.removeSeatCallback}
              rows={rows}
              maxReservableSeats={5}
              // alpha
              // visible
              selectedByDefault
              loading={loading}
              tooltipProps={{ multiline: true }}
              continuous
            />
          </div>
          <div className="col-lg-4 col-md-3 col-sm-3 col-xs-3"></div> */}
        </div>
      )
    }

  } 
}