import React, { Component, useState } from "react";
import "../../css/style.css";
import "../../css/seat.css";
import { Link } from "react-router-dom";

import baner from "../../images/header/bond50.jpg";
import PayData from "./dataToPayLK";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import PrimarySearchAppBar from "../../components/navBar/navBar";
import Preloader from "../../components/preloader/preloader";
import Footer from "../../components/footer/footer";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@material-ui/core/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import visa from "../../images/header/visa.png";
import master from "../../images/header/master.png";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import Stack from "@mui/material/Stack";

import queryString from "query-string";
var movieData = [];
var seatCount = [];
// var returUrl = 'https://site.tickets.lk/#/BookingConfirmed'
var returUrl = "http://localhost:3000/BookingConfirmed";

// const queryParam = queryString.parse(window.location.search);
// const newQueryParam = {
//    ...queryParam,
//    userId: 'admin',
//    reference: 'CLZ7NIKMJ7',
//    amount:300.00,
//    currency:'LKR',
//    returnUrl: 'https://site.tickets.lk/#/BookingConfirmed'
// }

var paramString = "https://linkbox.co/#/payment?";
var searchString = new URLSearchParams(paramString);
var movieInfo = [];
var totalPrice = 0.0;
var subtotal = 0.0;
var totalAdvanceCom = 0.0;
var adultprice = 0.0;
var childprice = 0.0;
let seatcatTypeNo = [];
var totalSeats = 0;
var adultqty = 0;
var childqty = 0;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export class BookingSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seatPrice: [],
      dataLoaded: false,
      priceList: [],
      advancedCommission: 0,
      value: 0,
      seatDetails: [],
      seatList: [],
      custData: [],
      refCode: 0,
      userName: "",
      email: "",
      mobile: "",
      otp: "",
      errorOtp: false,
      errorOtpBlank: false,
      mobileError: false,
      mobileOtpOk: false,
      emailError: false,
      guestName: "",
      guestEmail: "",
      guestMobile: "",
      cardDetails: {},
      cardNo: 0,
      cardNoId: 0,
      cardDataLoad: false,
      saveCard: false,
      cardSave: "N",
      submitButtonDisable: false,
      numberSet: false,
      submitBtn: true,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    // this.promohandleClose = this.promohandleClose.bind(this);
    // this.promohandleClickOpen =this.promohandleClickOpen.bind(this);
    this.submitData = this.submitData.bind(this);
    this.handleClickOpenOtp = this.handleClickOpenOtp.bind(this);
    this.handleCloseOtp = this.handleCloseOtp.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.verifyMobile = this.verifyMobile.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);
  }

  componentDidMount() {
    // this.getRefCode();
    // movieInfo = queryString.parse(this.props.location.search)
    // console.log(movieInfo)
    let urlData = new URLSearchParams(document.location.search);
    // movieInfo = queryString.parse(this.window.location)
    // console.log(urlData.get("seats"));
    movieInfo = {
      seats: urlData.get("seats"),
      movieName: urlData.get("movieName"),
      movieId: urlData.get("movieId"),
      venueId: urlData.get("venueId"),
      showId: urlData.get("showId"),
      showDate: urlData.get("showDate"),
      venue: urlData.get("venue"),
      showtime: urlData.get("showtime"),
    };
    // const accessToken = localStorage.getItem('AccessToken')
    // if(accessToken !== null){
    this.setState({
      seatList: JSON.parse(movieInfo.seats),
    });
    this.getSeatPrices(movieInfo);
    this.getCardDetails();
    // } else {
    // this.handleClickOpenOtp();
    // }
  }

  componentWillUnmount = () => {
    totalPrice = 0.0;
    totalAdvanceCom = 0.0;
    childprice = 0.0;
    adultprice = 0.0;
    seatcatTypeNo = [];
    adultqty = 0;
    childqty = 0;
    totalSeats = 0;
    subtotal = 0.0;
  };

  getSeatPrices(movieInfo) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        client_token: "ebd86470-7e90-4ece-9e89-1b6d4d2cbb61",
      },
    };
    fetch(
      `${process.env.REACT_APP_DEV_BASE_URL}/v1/seat/prices?venue_id=` +
        movieInfo.venueId +
        `&movie_id=` +
        movieInfo.movieId +
        `&showtime_id=` +
        movieInfo.showId +
        `&movie_date=` +
        movieInfo.showDate,
      requestOptions
    )
      .then((response) => response.json())
      .then((seatPrice) => {
        // console.log(seatPrice);
        this.setState({
          seatPrice: seatPrice.data.ticketPrices.priceList,
          advancedCommission: seatPrice.data.ticketPrices.advancedCommission,
          dataLoaded: true,
        });
        // console.log(this.state.seatPrice);

        this.state.seatList.forEach((element) => {
          // console.log(element);
          let typeNos = seatPrice.data.ticketPrices.priceList.filter(
            (type) => type.id === element.seatCategoryId
          );
          if (typeNos.length !== 0) {
            adultprice += typeNos[0].adultPrice * element.adultCount;
            childprice += typeNos[0].childPrice * element.childCount;
            totalAdvanceCom +=
              typeNos[0].adultCommission *
              (element.adultCount + element.childCount);
            subtotal +=
              typeNos[0].adultPrice * element.adultCount +
              typeNos[0].childPrice * element.childCount;
            totalPrice +=
              typeNos[0].adultPrice * element.adultCount +
              typeNos[0].childPrice * element.childCount +
              totalAdvanceCom;
            let prices = {
              seatType: typeNos[0].seatType,
              seat: element.seatNumbers.toString(),
              seatCount: element.adultCount + element.childCount,
            };
            seatcatTypeNo.push(prices);
            this.setState({
              seatData: seatcatTypeNo,
            });
            // console.log(seatcatTypeNo);
          }
        });

        this.countSeats();
        const filteredODCData = this.state.seatPrice.filter((type) =>
          type.seatType.includes("ODC")
        );
        // console.log(filteredODCData);
        const adultPrice = filteredODCData[0].adultPrice;
        const childPrice = filteredODCData[0].childPrice;
        // console.log(childPrice);
        const filteredBoxData = this.state.seatPrice.filter((type) =>
          type.seatType.includes("Box (1 Box = 2 Seats)")
        );
        const adultBoxPrice = filteredBoxData[0].adultPrice;
        const advanceCommision = this.state.advancedCommission;
        // console.log(adultPrice);
        // console.log(childPrice);
        // console.log(adultBoxPrice);
        // console.log(advanceCommision);

        this.setState({
          seatPrice: seatPrice.data.ticketPrices.priceList,
          adultPrice: adultPrice,
          childPrice: childPrice,
          adultBoxPrice: adultBoxPrice,
          advanceCommision: advanceCommision,
          dataLoaded: true,
        });
      })
      .catch(console.log);
  }

  countSeats() {
    // console.log(this.state.seatList);
    this.state.seatList.forEach((element) => {
      // console.log(element);
      adultqty = +element.adultCount;
      childqty = +element.childCount;
    });
    // console.log(adultqty, childqty);
    totalSeats = adultqty + childqty;
  }

  verifyMobile() {
    // console.log("email", this.state.email);

    this.setState({
      errorOtp: false,
      mobileError: false,
      emailError: false,
    });
    if (this.state.mobile === "") {
      this.setState({
        mobileError: true,
      });
    }
    if (this.state.email === "") {
      this.setState({
        emailError: true,
      });
    }
    if (this.state.mobile !== "" && this.state.email !== "") {
      fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/user/register`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          mobileNumber: this.state.mobile,
          email: this.state.email,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.success) {
            this.setState({
              mobileOtpOk: true,
            });
          } else {
            // console.log("error call mobile");
          }
        })
        .catch((err) => console.log(err));
    }
  }

  emailValidation() {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!this.state.email || regex.test(this.state.email) === false) {
      this.setState({
        error: "Email is not valid",
      });
      return false;
    }
    return true;
  }

  getRefCode() {
    // console.log(this.state.guestName);
    // console.log(this.state.guestEmail);
    // console.log(this.state.guestMobile);
    const firstname = this.state.userName;
    const lastname = "";
    const email = this.state.email;
    const mobile = this.state.mobile;

    let custData = [];
    custData.push(firstname, lastname, email, mobile);
    let customerData = {
      firstName: firstname,
      lastName: lastname,
      email: email,
      mobileNumber: mobile,
    };

    let seatDetails = [];
    let seatNos = [];
    seatDetails = JSON.parse(movieInfo.seats);
    const accessToken = localStorage.getItem("AccessToken");
    if (accessToken === null) {
      alert("no access Token");
    } else {
      fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/booking`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          client_token: "ebd86470-7e90-4ece-9e89-1b6d4d2cbb61",
          access_token: accessToken,
        },

        body: JSON.stringify({
          movieId: parseInt(movieInfo.movieId),
          venueId: parseInt(movieInfo.venueId),
          showtimeId: parseInt(movieInfo.showId),
          movieDate: movieInfo.showDate,
          seatDetails: seatDetails,
          customerDetails: customerData,
          promotionId: null,
          cardBin: null,
          cardSave: this.state.cardSave === "N" ? false : true,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.success) {
            this.setState({
              //   refCode: data.data.refcode,
              dataLoaded: true,
              //   refStatus: data.success,
            });
            // console.log(this.state.refCode);
            const userId = localStorage.getItem("userId");
            window.location.href = `${process.env.REACT_APP_DEV_BASE_URL_PAYMENT}/payment.php?id=${data.data.identityToken}`;
            // window.location.href = `${
            //   process.env.REACT_APP_DEV_BASE_URL_PAYMENT_GENIE
            // }?userId=${userId}&reference=${
            //   data.data.refcode
            // }&amount=${totalPrice}&currency=LKR&returnUrl=${encodeURIComponent(
            //   returUrl
            // )}&cardId=${this.state.cardNo}&cardSave=${this.state.cardSave}`;
          } else {
            // console.log("booking failed");
            window.location.href = "/BookingFailed?error=2";
          }
        })
        .catch((err) => console.log(err));
    }
  }

  getCardDetails() {
    const userId = localStorage.getItem("userId");
    if (userId == null) {
      return;
    }
    fetch(`https://site.tickets.lk/card/get.php?userId=${userId}&active=1`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.success) {
          this.setState({
            cardDetails: data.data,
            cardDataLoad: true,
          });
          // console.log(this.state.cardDetails);
        } else {
          // console.log("error cards");
        }
      })
      .catch((err) => console.log(err));
  }
  checkCard(e) {
    // console.log("checkid", Number(e.target.name));
    // var checked = e.target.checked
    // if(checked){
    this.setState({
      cardNo: e.target.value,
      cardNoId: e.target.name,
    });
    // console.log(this.state.cardNoId);
  }

  saveCardYesNo(e) {
    // console.log("save card ", e.target.checked);
    this.setState({ saveCard: !this.state.saveCard });
    // console.log(this.state.saveCard);
    if (this.state.saveCard) {
      this.setState({
        cardSave: "N",
      });
    } else {
      this.setState({
        cardSave: "Y",
      });
    }
  }

  handleClickOpen() {
    this.setState({ dialogOpen: true });
  }

  handleClose() {
    this.setState({ dialogOpen: false });
  }

  handleClickOpenOtp() {
    this.setState({ dialogOpenOtp: true });
  }

  handleCloseOtp() {
    this.setState({ dialogOpenOtp: false });
  }

  handleVerify() {
    this.setState({
      numberSet: true,
    });
  }

  setUsername = (event) => {
    this.setState({
      userName: event.target.value,
    });
  };

  setEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  setMobile = (event) => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      this.setState({ mobile: onlyNums });
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{10})/, "$1");
      this.setState({ mobile: number });
    }
  };

  setOtp = (event) => {
    this.setState({
      otp: event.target.value,
    });
  };

  verifyOtp() {
    if (this.state.otp === "") {
      this.setState({
        errorOtpBlank: true,
      });
    } else {
      fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/user/otp/validate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          mobileNumber: this.state.mobile,
          otp: this.state.otp,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.success) {
            this.setState({
              mobileOtpOk: false,
              submitBtn: false,
              userId: data.data.id,
              accessToken: data.data.token,
              otpButton: true,
            });
            localStorage.setItem("AccessToken", this.state.accessToken);
            localStorage.setItem("userId", this.state.userId);
          } else {
            // console.log("error call mobile");
            this.setState({
              errorOtp: true,
              mobileOtpOk: false,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  submitData(e) {
    e.preventDefault();
    localStorage.setItem("AccessToken", this.state.accessToken);
    localStorage.setItem("userId", this.state.userId);
    if (this.emailValidation()) {
      this.setState({
        guestName: this.state.userName,
        guestEmail: this.state.email,
        guestMobile: this.state.mobile,
      });
      if (this.state.mobile === "") {
        alert("Please enter Mobile No");
      } else {
        this.getRefCode();
      }
    }
    // console.log(this.state.guestName);
    // console.log(this.state.userName);
  }

  render() {
    const { seatPrice, dataLoaded, guestSubmit } = this.state;
    const adultPrice = this.state.adultPrice;
    const childPrice = this.state.childPrice;
    const adultBoxPrice = this.state.adultBoxPrice;
    const advanceCommision = this.state.advancedCommission;
    const seatCoun = seatCount.length;

    const AdSeatCount = seatCount.length - this.state.value;
    const CalAdultPrice = adultPrice * movieInfo.adultSeats;
    const CalChildPrice = childPrice * movieInfo.childSeats;
    const CalAdvanceCommission = advanceCommision * seatCount.length;
    const subTotal = CalAdultPrice + CalAdvanceCommission + CalChildPrice;

    // console.log(this.state.advancedCommission);

    const { dialogOpen, dialogOpen1, refCode, dialogOpenOtp } = this.state;
    // console.log(refCode);

    return (
      <div>
        {/* <Preloader/> */}
        {/* <SideNavBar /> */}
        <PrimarySearchAppBar />
        <div
          className="st_bt_top_header_wrapper float_left"
          style={{ paddingTop: 90 }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="st_bt_top_back_btn float_left">
                  {" "}
                  <a
                    href={
                      "/movieBooking?movieid=" + localStorage.getItem("movieId")
                    }
                    style={{ textDecoration: "none" }}
                  >
                    <i className="fas fa-long-arrow-alt-left"></i> &nbsp;Back
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="st_bt_top_center_heading float_left">
                  {/* <h3>Aquaman - English - (2:47)</h3> */}
                  <h3>{movieData.movieName} </h3>
                </div>
              </div>
              {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
					<div className="st_bt_top_center_heading float_left">	
					<h3>12:47</h3>
					</div>
				</div> */}
            </div>
          </div>
        </div>
        <div className="st_dtts_wrapper float_left">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="st_dtts_left_main_wrapper float_left">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="st_dtts_ineer_box float_left">
                        <ul>
                          <li>
                            <span className="dtts1">Movie</span>{" "}
                            <span className="dtts2">{movieInfo.movieName}</span>
                          </li>
                          <li>
                            <span className="dtts1">Date</span>{" "}
                            <span className="dtts2">{movieInfo.showDate}</span>
                          </li>
                          <li>
                            <span className="dtts1">Time</span>{" "}
                            <span className="dtts2">{movieInfo.showtime}</span>
                          </li>
                          <li>
                            <span className="dtts1">Theater</span>{" "}
                            <span className="dtts2">{movieInfo.venue}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="st_cherity_section float_left">
                        <div className="st_cherity_img float_left">
                          {/* <img src={baner} alt="img"/> */}
                        </div>
                        <div className="st_cherity_img_cont float_left">
                          {/* <div className="box">
											<p className="cc_pc_color1"/>
												<input type="checkbox" id="c201" name="cb"/>
												<label for="c201"><span>ADD Rs. 2</span> to your transaction as a donation. (Uncheck if you do not wish to donate)</label>
										</div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="st_cherity_btn float_left">
                        {/* <h3>SELECT TICKET TYPE</h3> */}
                        <br />
                        <ul>
                          <li>
                            {/* <a href="#" style={{textDecoration:'none'}} ><i className="flaticon-tickets"></i> &nbsp;M-Ticket</a> */}
                            {/* <Button variant="outlined" color="primary">
												Book As a Guest
											</Button> */}
                          </li>
                          {/* <li> */}
                          {/* <a href={'https://linkbox.co/#/payment?'+
										 "userId=" + 707 +
										 "&reference=" + "45685PO" +
										 "&amount=" + subTotal +
										 "&currency=" + "LKR" 
										 } style={{textDecoration:'none'}}><i className="flaticon-tickets"></i> &nbsp;Guest Checkout </a> */}
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ width: "70%" }}
                              onClick={this.handleClickOpen}
                            >
                              Book Now
                            </Button>
                          </Stack>
                          {/* <Button
                              variant="outlined"
                              color="secondary"
                              onClick={this.promohandleClickOpen}
                              disabled
                            >
                              Enter Promo Code
                            </Button> */}
                          <Dialog
                            open={dialogOpen1}
                            onClose={this.promohandleClose}
                            aria-labelledby="form-dialog-title-promo"
                            TransitionComponent={Transition}
                          >
                            <DialogTitle id="form-dialog-title-promo">
                              Enter Promo Code
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                To subscribe to this website, please enter your
                                email address here. We will send updates
                                occasionally.
                              </DialogContentText>
                              <TextField
                                // autoFocus
                                required
                                margin="dense"
                                id="Username"
                                label="Promo Code"
                                type="text"
                                ref="promoCode"
                                // variant="outlined"
                                // error
                                // fullWidth
                              />
                              <CheckCircleIcon
                                style={{ marginTop: 15, color: "#158c1d" }}
                              />
                              <ErrorIcon
                                style={{ marginTop: 15, color: "#f0d40a" }}
                              />
                              <br />
                              <Button color="secondary" variant="outlined">
                                Check
                              </Button>
                              <br />
                              <DialogContentText>
                                <small>
                                  To subscribe to this website, please enter
                                  your email address here. We will send updates
                                  occasionally.
                                </small>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={this.promohandleClose}
                                color="secondary"
                                variant="contained"
                              >
                                Cancel
                              </Button>
                              <Button color="primary" variant="contained">
                                Submit
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Dialog
                            open={dialogOpen}
                            onClose={this.handleClose}
                            aria-labelledby="form-dialog-title"
                            TransitionComponent={Transition}
                          >
                            <DialogTitle id="form-dialog-title">
                              User Verification
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                To proceed to payment, please verify your Mobile
                                Number. We will send an OTP to your Mobile.
                              </DialogContentText>
                              <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="mobile"
                                label="Mobile"
                                type="mobile"
                                // fullWidth
                                value={this.state.mobile}
                                onChange={this.setMobile.bind(this)}
                              />
                              <br />
                              {this.state.mobileError ? (
                                <small style={{ color: "#ed3939" }}>
                                  *Mobile No cannot be Blank
                                </small>
                              ) : null}
                              <br />
                              <TextField
                                // autoFocus
                                required
                                margin="dense"
                                id="email"
                                label="Email"
                                type="email"
                                // fullWidth
                                value={this.state.email}
                                onChange={this.setEmail.bind(this)}
                              />
                              {this.state.emailError ? (
                                <>
                                  <br></br>
                                  <small style={{ color: "#ed3939" }}>
                                    *Email cannot be Blank
                                  </small>
                                </>
                              ) : null}
                              <br />
                              {this.state.mobileOtpOk ? (
                                <TextField
                                  required
                                  // autoFocus
                                  margin="dense"
                                  id="otp"
                                  label="OTP"
                                  type="text"
                                  // fullWidth
                                  value={this.state.otp}
                                  onChange={this.setOtp.bind(this)}
                                />
                              ) : null}
                              <br />
                              {this.state.errorOtp ? (
                                <small style={{ color: "#ed3939" }}>
                                  *Incorrect OTP
                                </small>
                              ) : null}
                              {/* <br/>
                        {this.state.errorOtpBlank ?
                        <small style={{color: '#ed3939'}}>*OTP cannot be Blank</small> : null 
                        } */}
                              <br />
                              {this.state.mobileOtpOk ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={this.verifyOtp}
                                  disabled={this.state.otpButton}
                                >
                                  Verify OTP
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={this.verifyMobile}
                                  disabled={this.state.otpButton}
                                >
                                  Send OTP
                                </Button>
                              )}
                              <br />
                              <TextField
                                // autoFocus
                                // required
                                margin="dense"
                                id="Username"
                                label="Name"
                                type="text"
                                value={this.state.userName}
                                onChange={this.setUsername.bind(this)}
                                // fullWidth
                              />
                              <br />
                              <span className="text-danger">
                                {this.state.error}
                              </span>
                              <hr></hr>
                              {!this.state.cardDataLoad ? null : (
                                <FormControl>
                                  <RadioGroup
                                    name="cardsList"
                                    sx={{ display: "row", float: "right" }}
                                    checked={this.state.cardNo}
                                    value={this.state.cardNo}
                                    onChange={(e) => this.checkCard(e)}
                                  >
                                    {this.state.cardNo === null ? (
                                      <FormControlLabel
                                        value="0"
                                        control={<Radio />}
                                        name="0"
                                        label="Use Different Card"
                                      />
                                    ) : null}
                                    {/* <FormControlLabel value="0" control={<Radio />} name="0" label="Use Different Card"/> */}
                                    {this.state.cardDetails.cardDetails.map(
                                      (cards) => (
                                        <FormControlLabel
                                          value={cards.cardId}
                                          label={`${cards.maskCardNo}  ${cards.cardType}`}
                                          control={<Radio />}
                                          name={cards.cardId}
                                          id={cards.cardId}
                                        />
                                      )
                                    )}
                                  </RadioGroup>
                                  {this.state.cardNo === "0" ? (
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          value="active"
                                          checked={this.state.saveCard}
                                          onChange={(e) =>
                                            this.saveCardYesNo(e)
                                          }
                                        />
                                      }
                                      label="Save Card"
                                    />
                                  ) : null}
                                </FormControl>
                              )}
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={this.handleClose}
                                color="secondary"
                                variant="contained"
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={this.submitData}
                                color="primary"
                                variant="contained"
                                disabled={this.state.submitBtn}
                              >
                                Submit
                              </Button>
                            </DialogActions>
                          </Dialog>
                          {/* </li> */}
                          {/* <Dialog open={dialogOpenOtp} onClose={this.handleClose} 
											aria-labelledby="form-dialog-title" disableBackdropClick 
											disableEscapeKeyDown maxWidth='xs'>
											<DialogTitle id="form-dialog-title">Please Login to Continue</DialogTitle>
											<DialogContent>
												<DialogContentText>
												To subscribe to this website, please enter your email address here. We will send updates
												occasionally.
												</DialogContentText>
												{this.state.numberSet === false ?
													<TextField
													margin="dense"
													id="mobile"
													label="Mobile No"
													type="text"
													value=""
													// onChange={this.setUsername}
													/> :
													<TextField
													margin='dense'
													id='otp'
													label='OTP'
													type='text'
													value=""
													/>
												}
											</DialogContent>
											<DialogActions>
													<Button onClick={this.handleClose} color="secondary" variant="contained">
														Cancel
													</Button>
													{this.state.numberSet === false ?
														<Button onClick={this.handleVerify} color="primary" variant="contained">
															Verify
														</Button> :
														<Button onClick={this.handleVerify} color="primary" variant="contained">
															Submit
														</Button>
													}
											</DialogActions>
										</Dialog> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="st_dtts_bs_wrapper float_left">
                      <div className="st_dtts_bs_heading float_left">
                        <p>Booking Summary</p>
                      </div>
                      <div className="st_dtts_sb_ul float_left">
                        <ul>
                          <li>
                            Seat Types :{/* <br/><b>{movieInfo.seatType}</b> */}
                          </li>
                          {seatcatTypeNo.map((element) => (
                            <li
                              style={{
                                width: "230px",
                                wordBreak: "break-word",
                                color: "#ff4444",
                              }}
                            >
                              {element.seatType} | <b>{element.seat}</b> X{" "}
                              <b>{element.seatCount} </b> <br />
                            </li>
                          ))}
                          <li>
                            {adultqty} Adult Tickets
                            <br />{" "}
                            <span>
                              Rs.{" "}
                              {numberWithCommas(
                                adultprice.toFixed(2).toLocaleString()
                              )}
                            </span>
                          </li>
                          <li>
                            {childqty} Child Tickets
                            <br />
                            <span>
                              Rs.{" "}
                              {numberWithCommas(
                                childprice.toFixed(2).toLocaleString()
                              )}
                            </span>
                            {/* <br/>										 */}
                          </li>
                          {/* <li>
											{seatPrice.map(seatPrices =>(
												<p>{seatPrices.seatType}</p>
											))}
										</li> */}
                          <li>
                            <small>
                              Handling fees <br />
                              <span>
                                Rs.{" "}
                                {numberWithCommas(
                                  totalAdvanceCom.toFixed(2).toLocaleString()
                                )}
                              </span>
                            </small>
                          </li>
                          <li>
                            <small>
                              Donation fees <br />
                              <span>
                                Rs.{" "}
                                {numberWithCommas(
                                  CalAdvanceCommission.toFixed(
                                    2
                                  ).toLocaleString()
                                )}
                              </span>
                            </small>
                          </li>
                          <li></li>
                        </ul>
                        {/* <p>Booking Fees <span>Rs.60.00</span>
									</p>
									<p>Integrated GST (VAT) @ 8% <span>Rs.60.00</span>
									</p> */}
                      </div>
                      <div className="st_dtts_sb_h2 float_left">
                        <h3>
                          Sub total{" "}
                          <span>
                            Rs.{" "}
                            {numberWithCommas(
                              subtotal.toFixed(2).toLocaleString()
                            )}
                          </span>
                        </h3>
                        {/* <h4>Current State is <span>Colombo</span></h4> */}
                        <h5>
                          Payable Amount{" "}
                          <span style={{ color: "#ff4444" }}>
                            Rs.{" "}
                            {numberWithCommas(
                              totalPrice.toFixed(2).toLocaleString()
                            )}
                          </span>
                        </h5>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingSummary;
