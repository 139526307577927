import React from 'react'
import "../../css/style.css";
import "../../css/seat.css";




export default function TnC() {

    return(
        <div className="row scroll_to_booked_tickets">
    <h4>Acceptance of Terms</h4>
        <p>
        The following are the terms and conditions applicable to access and use of this website (or ‘site’). By gaining access to and using this site, you agree to comply with these terms and conditions and all applicable laws, rules and regulations.
        If you are not agreeable with these Terms of Use, kindly refrain from accessing and using the service provided by TicketsLK.
        Registering with and/or the use of the TicketsLK services and products, including and without limitation the TicketsLK web site, purchase of movie and/or other event tickets, and other similar products or services offered by TicketsLK, is indicative that you have read and understood the terms and conditions set forth below and agree to be legally bound by the said terms and conditions.
        We reserve the right to change these terms of use from time to time by posting the new version on the website. It is your responsibility to check regularly this page for the latest terms and conditions.
        </p>
    <h4>Permitted Use</h4>
    <p>The service provided by TicketsLK is for personal & non-commercial use.</p>

    <p>
        You hereby agree that you will not duplicate, download, publish, modify, distribute and/or use any material included in TicketsLK for any purpose, unless and otherwise you have obtained prior written permission from TicketsLK.
        You also agree that you will not use the services provided by TicketsLK for any purpose other than to subscribe and review the information in TicketsLK and purchase movie or event tickets for your personal use.
        Use of the material and / or content contained in TicketsLK for any purpose not set forth in these Terms of Use is strictly prohibited.
        </p>
    <h4>Suppliers Terms and Conditions for the Sale of Items</h4>
    <p>Terms and conditions will apply to individual items or groups of items in your transaction based upon who is supplying those items. The supplier of each item or group of items will be clearly displayed at time of booking and on your confirmation of purchase documents. Please make sure to read and understand the individual supplier’s terms and conditions in addition to these general conditions
    </p>
    <h4>Payment</h4>
    <p>All payments are subject to the terms and conditions of each bank and we have no authority over any payments made through the bank payment gateway. We do not retain your payment details or any other significant information such as your credit card number, passwords, pin number etc. Details of your credit / debit card may be securely held by the bank which processes your transaction.
        All prices include Value Added Tax (VAT) and Nation Building Tax as applicable.
        </p>
    <h4>All Sales are Final</h4>
    <p>No refunds, cancellations or exchanges will be entertained for any reason (date or time changes, partial performances, or lost tickets etc.) therefore make sure to check your payment details before you make your payments.
    </p>
    <h4>Change or Suspension of Site</h4>
    <p> We reserve the right to modify or discontinue, temporarily or permanently, this Site or any part of this Site with or without notice. You agree that we shall not be liable to you or any third party for any modification, suspension or discontinuance of the Site and / or any Services under this agreement, for any reason. We do not guarantee continuous, uninterrupted or secure access to our service, and operation of our Site which may be interfered with by numerous factors outside of our control. In addition, the Site could be unavailable during certain periods of time while it is being updated and modified. During this time, the Site will be temporarily unavailable.
    </p>
    <h4>Duplication of tickets (False ticket generation)</h4>
    <p>It is your responsibility to ensure that you carry the original confirmation receipt to theater with you
    </p>
    <h4>Registration, Accounts and Passwords</h4>
    <p> You agree to provide accurate, current and complete data about yourself on the TicketsLK registration form and keep your profile updated with accurate, current and complete information.

        You also understand that failure to maintain accurate, current and complete information in your profile will give TicketsLK adequate cause to suspend or terminate your use of its services.

        Upon registration you will receive a password and account name. Continued maintenance of confidentiality with regard to your account name and password will always remain your sole responsibility. You agree to bring to the immediate notice of TicketsLK any unauthorized use of your password or account or any other breach of security. You also agree to ensure that you exit from your account each time you use TicketsLK. Access to and use of password protected and/or secure areas of TicketsLK is restricted to its registered users only. Unauthorized attempts to access these areas of TicketsLK may be subject to prosecution.
    </p>
    <h4>Privacy Policy</h4>
    <p>Your use of TicketsLK is governed by its Privacy Policy. You hereby agree to TicketsLK’s collection, use and sharing of your information as set forth in the TicketsLK Privacy Policy.
    </p>
    <h4>Facility</h4>
    <p>You agree that the service provided by TicketsLK is that of an intermediary and you acknowledge that TicketsLK will not be held responsible for any inconveniences such as cancellations, delays etc. from its service providers.
    </p>
    <h4>User Conduct</h4>
    <p>You agree that you will not use TicketsLK to upload, post, or otherwise distribute or facilitate distribution of any material that is

        Libelous, defamatory or slanderous;
        Sexually suggestive or contains explicit sexual content (including nudity);
        Does or may denigrate or offend any individual or group on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;
        Does or may threaten, abuse, harass, or invade the privacy of any third party;
        Infringes the rights of any third party, including, without limitation, patent, trademark, trade secret, copyright, right of publicity, or other proprietary rights;
        Condones, promotes, contains or links to cracks, hacks or similar utilities or programs;
        Constitutes unauthorized or unsolicited advertising, junk or bulk e-mail (also known as “spam"?), chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling;
        Contains a software virus or any other computer code that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment, or to damage or obtain unauthorized access to any data or other information of any third party;
        Impersonates any person or entity, including any employee or representative of TicketsLK; or
        Violates any applicable law or these Terms of Use.
        You also agree that you will not collect information about third parties via TicketsLK or use any such information for the purpose of transmitting and / or facilitating transmission of unauthorized or unsolicited advertising, junk or bulk e-mail, chain letters, or any other form of unauthorized solicitation.

        You agree that you will not engage in the systematic retrieval of data or other content from TicketsLK to create or compile, directly or indirectly, a collection, compilation, database or directory, without TicketsLK’s prior written consent. You agree not to take any actions for the purpose of manipulating or distorting, or that may undermine the integrity and accuracy of, any ratings or reviews of any movie or other entertainment program, service or product that may be presented by TicketsLK.
    </p>
    <h4>Copyrights</h4>
    <p>TicketsLK respects the intellectual property of others, and we ask our users to do the same. If notified of allegedly infringing, defamatory, damaging, illegal, or offensive content, TicketsLK may, in its sole discretion, investigate the allegation and/or edit, remove or request the removal of such content. Notwithstanding the foregoing, TicketsLK does not ensure that any such content will be edited or removed. TicketsLK may terminate the accounts of users who infringe the intellectual property rights of others.
    </p>
    <h4>Proprietary Rights</h4>
    <p> TicketsLK owns all right, title and interest in and to this service and all materials and content contained in TicketsLK, including, without limitation, all content, site design, logos, button icons, images, digital downloads, data compilations, text, and graphics are protected by copyright, trademark and other intellectual property laws. Any unauthorized use of the materials provided as part of the TicketsLK is strictly prohibited.

        Permission is granted to individual consumers to electronically copy and to print hard copy portions of the Site solely for personal use. Any other use of materials on the site, including reproduction for purposes other than those noted above, modification, distribution, or republication, any form of data extraction or data mining, or other commercial exploitation of any kind, without prior written permission of an authorized officer of TicketsLK is strictly prohibited. You agree that you will not use any robot, spider, other automatic device, or manual process to monitor or copy our Web pages or the content contained therein without prior written permission of an authorized officer of TicketsLK.
    </p>
    <h4>Disclaimer of warranties</h4>
    <p> TicketsLK provides the service on an “as is? and “as available? basis. TicketsLK, its affiliates, agents and licensors disclaim all warranties and conditions, statutory or otherwise, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose and non infringement. Without limiting the foregoing, TicketsLK does not warrant that the service will (1) be uninterrupted; (2) be free from inaccuracies, errors, viruses or other harmful components; (3) meet your requirements; or (4) operate in the configuration or with the hardware or software you use. Your use of the service is solely at your risk.
    </p>
    <h4>Exclusion of damages</h4>
    <p>TicketsLK, and its subsidiaries and licensors will not be liable to you or any third party for any direct, indirect, punitive, incidental, special, consequential damages (including damages relating to lost profits, lost data or loss of goodwill) or any damages whatsoever that result from your use of or inability to use the service. This limitation applies whether the alleged liability is based on contract, tort, negligence, strict liability, or any other legal theory, and even if TicketsLK has been advised of the possibility of such damage. Without limiting the foregoing, TicketsLK will not be liable for any loss or damage arising out of (1) your failure to comply with section 3 (registration, accounts and passwords) or (2) content posted to the service by you or any third party.
    </p>
    <h4>Third-party web sites</h4>
    <p>The third-party web sites linked to or from the service are not controlled by TicketsLK. Accordingly, TicketsLK makes no warranties or conditions regarding such third-party web sites and will not be liable for any loss or damage caused by your use of or reliance on such web sites. Your use of third-party web sites is at your own risk. The inclusion on the service of a link to a third-party web site does not imply an endorsement by TicketsLK.
    </p>
    <h4>Indemnification</h4>
    <p>You will indemnify and hold harmless TicketsLK, from and against any damage, loss, cost or expense incurred in connection with any third-party claim, demand, proceeding or action brought against you arising out of your use of the Service or any alleged breach by you of any provision of these Terms of Use.
    </p>
    <h4>Termination</h4>
    <p>  In its sole and absolute discretion, with or without notice to you, TicketsLK may

        Suspend or terminate your use of the services provided by TicketsLK,
        Terminate your account at TicketsLK;
        You may terminate your account by clicking the “unsubscribe"? link. TicketsLK shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension of its service.
    </p>
    <h4>General</h4>
    <p> The Terms of Use and the relationship between you and TicketsLK shall be governed by the laws of the Republic of Sri Lanka. The section titles in these Terms of Use are for convenience only and have no legal or contractual effect.
        These terms and conditions are binding upon and shall ensure the benefit of both parties, their respective successors, heirs, executor, administrators, personal representatives and permitted assigns.
        You shall not assign your rights or obligations hereunder without TicketsLK’s prior written consent.
    </p>


        </div>

    )
    
}



