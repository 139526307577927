import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ShareIcon from "@material-ui/icons/Share";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 245,
    justifyContent: "center",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

export default function MovieCast(props) {
  const classes = useStyles();
  console.log(props);
  let cst = [];
  cst = props;
  return (
    <OwlCarousel className="owl-theme" margin={1} loop>
      {cst.cast.map((castList) => {
        return (
          <div
            class="st_summ_slider_cont float_left"
            style={{ justifyItems: "center", alignContent: "center" }}
          >
            {/* <img src={castList.image} alt="img" width="110px" height="110px" style={{borderRadius:60}}/>
                <h4><a href="#">{castList.name}</a></h4>
                <h5>{castList.type}</h5>
                <h6>As {castList.role}</h6> */}
            <Avatar
              alt="Remy Sharp"
              //   src={castList.image}
              className={classes.large}
            />
            <h4>
              <a href="#">{castList}</a>
            </h4>
            {/* <h6>{castList.type}</h6> */}
            {/* <h6>{castList.role}</h6> */}
          </div>
        );
      })}
    </OwlCarousel>
  );
}
