import React, { Component } from "react";
import "../../css/style.css";
import "../../css/seat.css";
import PrimarySearchAppBar from "../../components/navBar/navBar";
import { useLocation, Link } from "react-router-dom";
import PersonalData from "./userData";
import SupportChat from "./support";
import UserBookings from "./userBookings";
import queryString from "query-string";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      dataLoaded: false,
      numberSet: false,
      mobile: "",
      otp: "",
      verifySuccess: false,
      otpVerifySuccess: false,
      userId: "",
      accessToken: "",
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.handleVerifyOtp = this.handleVerifyOtp.bind(this);
  }

  componentDidMount() {
    const accessToken = localStorage.getItem("AccessToken");
    if (accessToken !== null) {
      // console.log(accessToken, "null");
    } else {
      // console.log(accessToken, "not null");
      this.handleClickOpen();
    }
    // const requestOptions ={
    //     method: 'GET',
    //     headers: { 'Content-Type': 'application/json', 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61' }
    // };
    // fetch(`http://sandbox-api.tickets.lk/v1/booking?userId=7&limit=10`, requestOptions)
    // .then(response => response.json())
    // .then((userData) => {
    //   console.log(userData.data.userBookings)
    //   this.setState({
    //     userData: userData.data.userBookings,
    //     dataLoaded: true,
    //    })
    // }).catch(console.log);
  }

  handleClickOpen() {
    this.setState({ dialogOpen: true });
  }

  handleClose() {
    this.setState({ dialogOpen: false });
  }

  handleVerify = async () => {
    try {
      await fetch(`${process.env.REACT_APP_DEV_BASE_URL}/v1/user/register`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61',
          // 'access_token': '978ea883-4bb6-4f58-95a3-0ad1486d3ea9',
        },
        body: JSON.stringify({
          // userId: 505,
          mobileNumber: this.state.mobile,
        }),
      })
        .then((response) => response.json())
        .then((mobileVerify) => {
          // console.log(mobileVerify);
          this.setState({
            verifySuccess: mobileVerify.success,
          });
          if (mobileVerify.success === true) {
            // console.log("truue");
            this.setState({
              numberSet: true,
            });
          } else {
            // console.log("falllls");
            this.setState({
              numberSet: false,
            });
          }
        })
        .catch(console.log);
      // console.log(this.state.verifySuccess);
    } catch (error) {
      console.log("error", error);
    }
  };

  handleVerifyOtp = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/v1/user/otp/validate`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // 'client_token': 'ebd86470-7e90-4ece-9e89-1b6d4d2cbb61',
            // 'access_token': '978ea883-4bb6-4f58-95a3-0ad1486d3ea9',
          },
          body: JSON.stringify({
            // userId: 505,
            mobileNumber: this.state.mobile,
            otp: this.state.otp,
          }),
        }
      )
        .then((response) => response.json())
        .then((otpVerify) => {
          // console.log(otpVerify.data);
          this.setState({
            otpVerifySuccess: otpVerify.success,
            userId: otpVerify.data.id,
            accessToken: otpVerify.data.token,
          });
          if (otpVerify.data.id === null) {
            alert("Wrong OTP entered");
          } else {
            localStorage.setItem("AccessToken", otpVerify.data.token);
            localStorage.setItem("userId", otpVerify.data.id);
            // console.log(localStorage.getItem("AccessToken"));
            this.handleClose();
          }
        })
        .catch(console.log);
      // console.log(this.state.seatsData);
    } catch (error) {
      console.log("error", error);
    }

  };

  setMobile = (event) => {
    this.setState({
      mobile: event.target.value,
    });
  };

  setOtp = (event) => {
    this.setState({
      otp: event.target.value,
    });
  };

  render() {
    const { userData, dataLoaded, dialogOpen } = this.state;
    // console.log(userData);

    return (
      <div>
        <PrimarySearchAppBar />
        <h4 style={{ paddingTop: 40 }}></h4>
        <div className="row">
          <div
            className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
            style={{ borderRight: "2px solid #a8a7a7", paddingInline: 1 }}
          >
            <PersonalData />
          </div>
          <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
            <UserBookings dataParentToChild={userData} dataLoad={this.state.dataLoaded} />
          </div>
          {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12" 
                    style={{borderLeft: "2px solid #a8a7a7", backgroundColor: '#000' }}>
                        <SupportChat/>
                    </div> */}
        </div>
        <Dialog
          open={dialogOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
        >
          <DialogTitle id="form-dialog-title">
            Please Verify your Mobile Number to Continue
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To Login to this website, please enter your Mobile Number here &
              Verify.
            </DialogContentText>
            {!this.state.numberSet ? (
              <TextField
                margin="dense"
                id="mobile"
                label="Mobile No"
                type="text"
                value={this.state.mobile}
                onChange={this.setMobile.bind(this)}
              />
            ) : (
              <TextField
                margin="dense"
                id="otp"
                label="OTP"
                type="text"
                value={this.state.otp}
                onChange={this.setOtp.bind(this)}
              />
            )}
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.handleClose} color="secondary" variant="contained">
                            Cancel
                        </Button> */}
            <Button color="secondary" variant="contained">
              <Link to="/" style={{ textDecoration: "none", color: "#FFF" }}>
                Cancel
              </Link>
            </Button>
            {this.state.numberSet === false ? (
              <Button
                onClick={this.handleVerify}
                color="primary"
                variant="contained"
              >
                Verify
              </Button>
            ) : (
              <Button
                onClick={this.handleVerifyOtp}
                color="primary"
                variant="contained"
              >
                Submit
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Profile;
